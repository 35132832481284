body {
  margin: 0;
  padding: 0;
}

.carouselContainer {
  position: relative;
  overflow: hidden;
  margin-top: 40px;
  width: 100%;
  max-width: 100%;
}

.carousel {
  display: flex;
  overflow: hidden;
}

.slide {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  flex: 0 0 100%;
}

.imagemCliente {
  width: 443px;
  height: 339px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
}
.imagemCliente2 {
  width: 443px;
  height: 339px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
}
.imagemCliente3 {
  width: 443px;
  height: 339px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
}
.imagemCliente4 {
  width: 443px;
  height: 339px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
}
.imagemCliente5 {
  width: 443px;
  height: 339px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
}
.imagemCliente6{
  width: 443px;
  height: 339px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
}
.imagemCliente7 {
  width: 443px;
  height: 339px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
}
.imagemCliente8 {
  width: 443px;
  height: 339px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
}

.aspas {
  width: 25px;
  height: 22px;
  fill: #000;
  opacity: 0.5396153330802917;
  margin-left: 50%;
  margin-bottom: 45.05px;
}
.aspas2 {
  width: 25px;
  height: 22px;
  fill: #000;
  opacity: 0.5396153330802917;
  margin-left: 50%;
  margin-bottom: 45.05px;
}
.aspas3 {
  width: 25px;
  height: 22px;
  fill: #000;
  opacity: 0.5396153330802917;
  margin-left: 50%;
  margin-bottom: 45.05px;
}
.aspas4 {
  width: 25px;
  height: 22px;
  fill: #000;
  opacity: 0.5396153330802917;
  margin-left: 50%;
  margin-bottom: 45.05px;
}
.aspas5 {
  width: 25px;
  height: 22px;
  fill: #000;
  opacity: 0.5396153330802917;
  margin-left: 50%;
  margin-bottom: 45.05px;
}
.aspas6 {
  width: 25px;
  height: 22px;
  fill: #000;
  opacity: 0.5396153330802917;
  margin-left: 50%;
  margin-bottom: 45.05px;
}
.aspas7 {
  width: 25px;
  height: 22px;
  fill: #000;
  opacity: 0.5396153330802917;
  margin-left: 50%;
  margin-bottom: 45.05px;
}
.aspas8 {
  width: 25px;
  height: 22px;
  fill: #000;
  opacity: 0.5396153330802917;
  margin-left: 50%;
  margin-bottom: 45.05px;
}


.fraseCliente {
  margin-left: 100px;
  margin-right: 62px;
  display: flex;
  width: 361px;
  flex-direction: column;
  color: #514C4C;
  font-family: 'Noto Sans', sans-serif;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.fraseCliente2 {
  margin-left: 100px;
  margin-right: 62px;
  display: flex;
  width: 361px;
  flex-direction: column;
  color: #514C4C;
  font-family: 'Noto Sans', sans-serif;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.fraseCliente3 {
  margin-left: 100px;
  margin-right: 62px;
  display: flex;
  width: 361px;
  flex-direction: column;
  color: #514C4C;
  font-family: 'Noto Sans', sans-serif;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.fraseCliente4 {
  margin-left: 100px;
  margin-right: 62px;
  display: flex;
  width: 361px;
  flex-direction: column;
  color: #514C4C;
  font-family: 'Noto Sans', sans-serif;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.fraseCliente5 {
  margin-left: 100px;
  margin-right: 62px;
  display: flex;
  width: 361px;
  flex-direction: column;
  color: #514C4C;
  font-family: 'Noto Sans', sans-serif;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.fraseCliente6 {
  margin-left: 100px;
  margin-right: 62px;
  display: flex;
  width: 361px;
  flex-direction: column;
  color: #514C4C;
  font-family: 'Noto Sans', sans-serif;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.fraseCliente7 {
  margin-left: 100px;
  margin-right: 62px;
  display: flex;
  width: 361px;
  flex-direction: column;
  color: #514C4C;
  font-family: 'Noto Sans', sans-serif;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.fraseCliente8{
  margin-left: 100px;
  margin-right: 62px;
  display: flex;
  width: 361px;
  flex-direction: column;
  color: #514C4C;
  font-family: 'Noto Sans', sans-serif;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.cliente {
  color: #000;
  font-family: 'Noto Sans', sans-serif;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  margin-left: 47.5%;
  margin-top: 29px;
}

.arrows {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 95%;
  display: flex;
  justify-content: space-between;
}

.arrow2 {
  cursor: pointer;
  font-size: 24px;
}

.arrowLeft2 {
  margin-left: 320px;
  width: 10.6px;
  height: 22.34px;
  flex-shrink: 0;
  background-image: url(./img/seta\ 2\ esquerda.png );

}

.arrowRight2 {

  margin-right: 176.4px;
  width: 10.6px;
  height: 22.34px;
  flex-shrink: 0;
  margin-left: 83px;
  background-image: url(./img/seta\ 2\ direita.png );
}

.inputContainer {
  display: flex;
  justify-content: center;
  margin-top: 86px;
  margin-bottom: 108px;
}

.input {
  width: 44px;
  height: 8px;
  margin: 0 0px;
  background-color: #D7D7D7;
  border: 1px solid #D7D7D7;
  border-radius: 20%;
  transition: background-color 0.3s ease-in-out;
}

.input.black {
  background-color: black;
}
