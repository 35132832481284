@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&family=Noto+Sans:wght@400;700&family=Yeseva+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Noto+Sans&family=Yeseva+One&display=swap');
:root {
    --after-background-color-default: white;
    --after-background-color-alternate: black; 
}
html{
   
        scroll-behavior: smooth;
      
}

*{
  box-sizing: border-box;
  padding:0;
  margin:0;
}







body {
    background-color: #f6f6f6;
 
}

p {
    margin: 0;
    padding: 0;
}

.header {
    background-color: #262523;
    padding: 10px;
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 3;
    min-width: 100%;
    max-width: 100%;
}

.logo2 img {
    width: 2.27094rem;
    height: 2.21031rem;
    left: 10px;
    top: 38.36px;
    border-radius: 0px;
    display: inline-block;
    margin-left: 5vw;
    margin-top: 39.32px;
}

.nayara-guimaraes-abreviacao {
    color: #f6f6f6;
    font-family: 'Yeseva One', cursive;
    font-style: normal;
    font-size: 2.5rem;
    margin-left: 14px;
    margin-top: 34px;
    
}

li {
    display: inline-block;
    cursor: pointer;
    transition: all 0.2s;
}

nav ul {
    list-style: none;
    display: flex;
    padding-left: 36vw;

}

nav ul li {
    margin-right: 9vh;
    margin-top: 40px;
}

nav ul li a {
    text-decoration: none;
display: block;
    color: #D7D7D7;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 1.5rem;
   
}

li a::after {
    content: '';
    width: 0;
    height: 3px;
    display: block;
    background-color: var(--after-background-color-default);
    margin: auto;
}

li a.active::after {
    background-color: var(--after-background-color-alternate);
}




li a:hover::after {
    width: 100%;
    transition: 0.2s;
    display: block;
   
}

 li a:hover {
    font-weight: bold;
   
}

.hamburger {
    display: none;
    height: fit-content;
    cursor: pointer;
    padding: 0px 8px;
    border-radius: 5px;
    transition: 0.2s;
    z-index: 4;
    margin-top: 40px;
    margin-left: 220px;
}

.hamburger div {
    width: 30px;
    height: 2px;
    margin: 6px 0;
    background: #ffff;
}

#home {
    width: 100%;
    max-width: 100%;
   height: 1000px;
    background: #262523;
}

.container-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
}

.img-container {
    position: absolute;
    width: 42.90%;
  
    height:867px;
    
    
    left: 57.1%;
    top: 134px;
}

.frase {
    width:338px;
    height: auto;
    margin-left: 10%;
   
    margin-top: 204px;
    color: #FFF;
    font-family: 'Noto Sans', sans-serif;
    font-size: 3.8125rem;
    font-style: normal;
    font-weight: 400;
    line-height:  103.279%;


}

.frase2 {
    position: absolute;
    width: 470px;
    height: 150px;
    left: 15%;
    top: 770px;
    bottom: 104px;
    right: 0px;
    font-family: 'Noto Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 25px;
    color: #D7D7D7;


}

.img-container2 {
    position:absolute;
    width: 12%;
 
    height: 11vw;
    left:50%;
    top: 214px;
    z-index: 2;
    filter: drop-shadow(0px 4px 27px rgba(0, 0, 0, 0.25));
}


#funciona {
    width:100%;
    max-width: 100%;
    height:1100px;
    background: #f6f6f6;
}

.funciona-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
}

.titulo {
    font-size: 2.5rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    display: flex;
    width: 61%;
    margin-bottom: 18px;
    margin-top: 110px;
    color: #000;
    float: center;
    margin-right:19.5% ;
    margin-left: 18.5%;
}

.legenda {
    display: flex;
    width: 60%;
    color: #000;
    font-size: 1.25rem;
    font-family: 'Noto Sans', sans-serif;
    margin-bottom: 52px;
    font-weight: 400;
    line-height: normal;
    font-style: normal;
    float: center;
    margin-right: 20%;
    margin-left: 19%;
    
}

.img-legenda {
    width: 60%;
  margin-left: 19%;
    height:750px;
  
}

.planejamento {
    margin-left: 12%;
    margin-top: 167px;
    color: #000;
    font-size: 2rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    margin-bottom: 40px;
}

.passo1-legenda1 {
    display: flex;
}

.passo1 {
    margin-left:13%;
    margin-right: 2px;
    color: #979595;
    font-size: 1.5rem;
    font-family: 'Noto Sans', sans-serif;
}

.um {
    color: #979595;
    font-size: 1.5rem;
    font-family: 'Noto Sans', sans-serif;
    padding-right: 4rem;
}

.legenda-passo1 {
    color: #514C4C;
    font-size: 1.4375rem;
    font-family: 'Noto Sans', sans-serif;
    display: flex;
    width: 501px;
    flex-direction: column;
    flex-shrink: 0;
    margin-bottom: 40px;
   
}

.linha-horizontal {
    width: 640px;
    border: 1px solid #C4BABA;
    margin-left: 12%;
    margin-bottom: 50px;
}

.estimativa {
    margin-left: 12%;
    color: #000;
    font-size: 2rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    margin-bottom: 40px;

}

.passo2-legenda2 {
    display: flex;
}

.passo2 {
    margin-left: 13%;
    color: #979595;
    font-size: 1.5rem;
    font-family: 'Noto Sans', sans-serif;
}

.dois {
    color: #979595;
    font-size: 1.5rem;
    font-family: 'Noto Sans', sans-serif;
    padding-right: 4rem;
    margin-left: 5px;
}

.legenda-passo2 {
    color: #514C4C;
    font-size: 1.4375rem;
    font-family: 'Noto Sans', sans-serif;
    display: flex;
    margin-bottom: 40px;
    flex-direction: column;
    flex-shrink: 0;
    width: 531px;
}

.linha-horizontal2 {
    width: 640px;
    border: 1px solid #C4BABA;
    margin-left: 12%;
    margin-bottom: 50px;
}

.construcao {
    margin-left: 12%;
    color: #000;
    font-size: 2rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    margin-bottom: 40px;
}

.passo3-legenda3 {
    display: flex;
}

.passo3 {
    margin-left: 13%;
    color: #979595;
    font-size: 1.5rem;
    font-family: 'Noto Sans', sans-serif;
    margin-right: 3px;
}

.tres {
    color: #979595;
    font-size: 1.5rem;
    font-family: 'Noto Sans', sans-serif;
    padding-right: 4rem;
}

.legenda-passo3 {
    color: #514C4C;
    font-size: 1.4375rem;
    font-family: 'Noto Sans', sans-serif;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    margin-bottom: 40px;
    width: 520px;
}
.linha-horizontal3 {
    width: 640px;
    border: 1px solid #C4BABA;
    margin-left: 12%;
    margin-bottom: 60px;
}

#projeto {
    width: 100%;
    max-width: 100%;
    height:1200px;
    background: #F6F6F6;
    transition-timing-function: ease-in;
}

.ImageTriangulo {
    width: 100%;
    height: 640px;
}

.melhor {
    color: #FFF;
    font-size: 2.5rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    margin-left: 87.27px;
    margin-top: -520px;
    margin-bottom: 18px;
}

.frase-melhor {
    color: #D7D7D7;
    font-size: 1.5rem;
    font-family: 'Noto Sans', sans-serif;
    display: flex;
    width: 1284px;
    flex-direction: column;
    margin-left: 87.27px;
}

.quantidade {
    color: #000;
    font-size: 1.5rem;
    font-family: 'Noto Sans', sans-serif;
    font-weight: 700;
    margin-left: 90px;
    margin-top: 150px;
    margin-bottom: 13px;
}

.frase-quantidade {
    color: #000;
    font-size: 1.5rem;
    font-family: 'Noto Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 92px;
    margin-right: 168px;
}

.quantidade2 {
    color: #000;
    font-size: 1.5rem;
    font-family: 'Noto Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-left: 485px;
  
    margin-bottom: 15px;
}

.frase-quantidade2 {
    color: #000;
    font-size: 1.5rem;
    font-family: 'Noto Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 485px;
}

.slide-container {
    width: 100%;
    max-width: 100%;
    position: relative;
    overflow: hidden;
    margin-top: 100px;
}

.slide-track {
    width: calc(460px * 3);
    display: flex;
    transition: transform 0.3s ease-in-out;
}

.slide-track img {
    width: 580px;
    height: 320px;
    margin-right: 32px;
}

.arrow {
    position: absolute;
    top: 35%;
    transform: translateY(-50%);
    width: 10.6px;
    height: 22.34px;
    flex-shrink: 0;
    cursor: pointer;
}

.left-arrow {
    left: 10px;
    background-image: url(./img/Vector\ \(1\).svg);
}

.right-arrow {
    right: 10px;
    background-image: url(./img/Vector.svg);
}

.input-control {
    display: flex;
    justify-content: center;
    margin-top: 52px;
    padding-bottom: 120px;
}

.carousel-input {
    display: inline-block;
    width: 30px;
    height: 8px;
    background-color: #D7D7D7;
    border-radius: 0%;
    margin: 0 0px; /*    separar em barrinhas      */
    border: 1px solid #D7D7D7;
    transition: background-color 0.3s ease-in-out;
}

.carousel-input.active {
    background-color: #000;
}

.frase3 {
    color: #000;
    font-size: 2rem;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  text-align: center;
}



#contatos {
    max-width: 100%;
    width: 100%;
    height: 800px;
    background: #F6F6F6;
}

.frase-contato {
    color: #000;
    font-family: 'Montserrat', sans-serif;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    margin-top: 800px;
    padding-top: 162px;
    margin-bottom: 28px;
    margin-left: 1%;
    margin-right: 1%;
}

.frase-contato2 {
    color: #000;
    font-family: 'Noto Sans', sans-serif;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center ;
    margin-bottom: 115px;
    margin-left: 1%;
    margin-right: 1%;
}

.butao {
    border: 1px solid black;
    transition: border-color 0.5s, background-color 0.5s, color 0.5s;
    width: 56%;
    
    justify-content: center;
    padding: 15px 28px;
    flex-shrink: 0;
    margin-left: 22.5%;
    margin-right:0;
    background-color: #000;
    gap: 10px;
    display: flex;
    cursor: pointer
}

.butao:hover {
    border: 1px solid black;
    color: #444;
    background-color: #f6f6f6;
}

.enviar {
    text-align: center;
    text-decoration: none;
    color: #ffff;
    list-style: none;
    font-family: 'Noto Sans', sans-serif;
    font-size: 1.625rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    list-style: none;
}

a:link {
    text-decoration: none;
}

footer {
    width: 100%;
    max-width: 100%;
    height: 400px;
    flex-shrink: 0;
    background: #262523;
}

.footer-flex {
    display: flex;
    justify-content: center;
}

.icon-footer {
    width: 57.34px;
    height: 53.37px;
    margin-top: 29.82px;
    filter: invert(100%);
}

.frase-lado-icon {
    color: #FFF;
    font-family: 'Yeseva One', cursive;
    font-size: 3rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 29px;
    margin-bottom: 20px;
    text-align: center;
    
}

.funcao {
    color: #FFF;
    font-family: 'Noto Sans', sans-serif;
    font-size: 1.625rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    align-items: center;
    margin-bottom: 20px;
}

.endereco {
    color: #FFF;
    font-family: 'Noto Sans', sans-serif;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    margin-bottom: 20px;
}

.email-contato {
    color: #FFF;
    font-family: 'Noto Sans', sans-serif;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    margin-bottom: 20px;
}

.telefone {
    color: #FFF;
    font-family: 'Noto Sans', sans-serif;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    margin-bottom: 20px;
}

.icons {
    display: flex;
    justify-content: center;
}

.insta {
    
    width: 48px;
    height: 48px;
    margin-right: 32px;
}

.icon-whatsapp {
    width: 48px;
    height: 48px;
    margin-right: 32px;
}

.linkedin {
    width: 48px;
    height: 48px;
 
}

.direitos-reservados {
    color: #FFF;
    font-family: 'Noto Sans', sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 78px;
    margin-bottom: 17px;
}

nav .mobile-logo {
    display: none;
}

.mobile-nayara-guimaraes-aabrevicao {
    display: none;
}

.mobile-logo {
    display: none;
}

.mobile-planejamento,
.passo1-legenda12,
.um2,
.mobile-legenda1-passo1,
.mobile-linha-horizontal1,
.mobile-estimativa,
.passo2-legenda21,
.mobile-passo2,
.dois2,
.mobile-legenda-passo2,
.mobile-linha-horizontal2,
.mobile-construcao,
.passo3-legenda31,
.mobile-passo3,
.tres2,
.legenda-mobile-passo3,
.mobile-linha-horizontal3 {
    display: none;

}

.mobile-passo1 {
    display: none;
}

.mobile {
    display: none;
}
@media (max-width: 1613px){
    .logo2 img {
        width: 2.27094rem;
        height: 2.21031rem;
        left: 10px;
        top: 38.36px;
        border-radius: 0px;
        display: inline-block;
        margin-left: 5vw;
        margin-top: 39.32px;
    }
    
    .nayara-guimaraes-abreviacao {
        color: #f6f6f6;
        font-family: 'Yeseva One', cursive;
        font-style: normal;
        font-size: 2.5rem;
        margin-left: 14px;
        margin-top: 34px;
    }
    
    li {
        display: inline-block;
        cursor: pointer;
        transition: all 0.2s;
    }
    
    nav ul {
        list-style: none;
        display: flex;
        padding-left: 25vw;
    
    }
    nav ul li {
        margin-right: 6vw;
        margin-top: 40px;
    }
    
    nav ul li a {
        text-decoration: none;
       
        color: #D7D7D7;
        font-family: 'Montserrat', sans-serif;
        font-weight: 400;
        font-size: 1.5rem;
       
    }
    .slide-track img {
        width: 480px;
        height: 320px;
        margin-right: 32px;
    }
    .titulo {
        font-size: 2.4rem;
        font-family: 'Montserrat', sans-serif;
        font-weight: 600;
        display: flex;
        width: 80%;
        margin-bottom: 18px;
        margin-top: 110px;
        color: #000;
        float: center;
        
        margin-left: 11.5%;
    }
    
    .legenda {
        display: flex;
        width: 80%;
        color: #000;
        font-size: 1.5rem;
        font-family: 'Noto Sans', sans-serif;
        margin-bottom: 52px;
        font-weight: 400;
        line-height: normal;
        font-style: normal;
        float: center;
       
        margin-left: 12%;
        
    }
   

    .img-legenda {
        width: 80%;
      margin-left: 12%;
        height:750px;
      
    }


    

    .arrowLeft2 {
        margin-left: 120px;
        width: 10.6px;
        height: 22.34px;
        flex-shrink: 0;
        background-image: url(./img/seta\ 2\ esquerda.png );
      
      }
      
      .arrowRight2 {
      
        margin-right:5px;
        width: 10.6px;
        height: 22.34px;
        flex-shrink: 0;
        margin-left: 83px;
        background-image: url(./img/seta\ 2\ direita.png );
      }
    
    
}



@media (max-width: 1359px){

    nav ul {
        list-style: none;
        display: flex;
        padding-left: 22vw;
    
    }
    nav ul li {
        margin-right: 5vw;
        margin-top: 40px;
    }
    
    nav ul li a {
        text-decoration: none;
       
        color: #D7D7D7;
        font-family: 'Montserrat', sans-serif;
        font-weight: 400;
        font-size: 1.5rem;
       
    }
    .slide-track img {
        width: 422px;
        height: 320px;
        margin-right: 32px;
    }
    .quantidade {
        color: #000;
        font-size: 1.5rem;
        font-family: 'Noto Sans', sans-serif;
        font-weight: 700;
        margin-left: 90px;
        margin-top: 160px;
        margin-bottom: 13px;
    }
    
    


    .planejamento {
        margin-left: 12%;
        margin-top: 167px;
        color: #000;
        font-size: 2rem;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        margin-bottom: 40px;
    }
    
    .passo1-legenda1 {
        display: flex;
    }
    
    .passo1 {
        margin-left:13%;
        margin-right: 2px;
        color: #979595;
        font-size: 1.3rem;
        font-family: 'Noto Sans', sans-serif;
    }
    
    .um {
        color: #979595;
        font-size: 1.3rem;
        font-family: 'Noto Sans', sans-serif;
        padding-right: 4%;
    }
    
    .legenda-passo1 {
        color: #514C4C;
        font-size: 1.25rem;
        font-family: 'Noto Sans', sans-serif;
        display: flex;
        width: 450px;
        flex-direction: column;
        flex-shrink: 0;
        margin-bottom: 40px;
       
    }
    
    .linha-horizontal {
        width: 540px;
        border: 1px solid #C4BABA;
        margin-left: 12%;
        margin-bottom: 50px;
    }
    
    .estimativa {
        margin-left: 12%;
        color: #000;
        font-size: 2rem;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        margin-bottom: 40px;
    
    }
    
    .passo2-legenda2 {
        display: flex;
    }
    
    .passo2 {
        margin-left: 13%;
        color: #979595;
        font-size: 1.3rem;
        font-family: 'Noto Sans', sans-serif;
    }
    
    .dois {
        color: #979595;
        font-size: 1.3rem;
        font-family: 'Noto Sans', sans-serif;
        padding-right: 4%;
        margin-left: 5px;
    }
    
    .legenda-passo2 {
        color: #514C4C;
        font-size: 1.25rem;
        font-family: 'Noto Sans', sans-serif;
        display: flex;
        margin-bottom: 40px;
        flex-direction: column;
        flex-shrink: 0;
        width: 450px;
    }
    
    .linha-horizontal2 {
        width: 540px;
        border: 1px solid #C4BABA;
        margin-left: 12%;
        margin-bottom: 50px;
    }
    
    .construcao {
        margin-left: 12%;
        color: #000;
        font-size: 2rem;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        margin-bottom: 40px;
    }
    
    .passo3-legenda3 {
        display: flex;
    }
    
    .passo3 {
        margin-left: 13%;
        color: #979595;
        font-size: 1.3rem;
        font-family: 'Noto Sans', sans-serif;
        margin-right: 3px;
    }
    
    .tres {
        color: #979595;
        font-size: 1.3rem;
        font-family: 'Noto Sans', sans-serif;
        padding-right: 4rem;
    }
    
    .legenda-passo3 {
        color: #514C4C;
        font-size: 1.25rem;
        font-family: 'Noto Sans', sans-serif;
        display: flex;
        flex-shrink: 0;
        flex-direction: column;
        margin-bottom: 40px;
        width: 410px;
    }
    .linha-horizontal3 {
        width: 540px;
        border: 1px solid #C4BABA;
        margin-left: 12%;
        margin-bottom: 60px;
    }
   
    
    
}
@media (max-width: 1183px){
    nav ul {
        list-style: none;
        display: flex;
        padding-left: 12vw;
    
    }
    nav ul li {
        margin-right: 5vw;
        margin-top: 40px;
    }
    
    nav ul li a {
        text-decoration: none;
       
        color: #D7D7D7;
        font-family: 'Montserrat', sans-serif;
        font-weight: 400;
        font-size: 1.5rem;
       
    }
    .slide-track img {
        width: 405px;
        height: 320px;
        margin-right: 32px;
    }
    .quantidade {
        color: #000;
        font-size: 1.5rem;
        font-family: 'Noto Sans', sans-serif;
        font-weight: 700;
        margin-left: 90px;
        margin-top: 185px;
        margin-bottom: 13px;
    }

    .titulo {
        font-size: 1.9rem;
        font-family: 'Montserrat', sans-serif;
        font-weight: 600;
        display: flex;
        width: 100%;
        margin-bottom: 18px;
        margin-top: 110px;
        color: #000;
        float: center;
        
    }



    .frase2 {
        position: absolute;
        width: 450px;
        height: 150px;
        left: 8%;
        top: 770px;
        bottom: 104px;
        right: 0px;
        font-family: 'Noto Sans', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 25px;
        color: #D7D7D7;
    
    
    }

    .planejamento {
        margin-left: 7%;
        margin-top: 167px;
        color: #000;
        font-size: 2rem;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        margin-bottom: 40px;
    }
    
    .passo1-legenda1 {
        display: flex;
    }
    
    .passo1 {
        margin-left:8%;
        margin-right: 2px;
        color: #979595;
        font-size: 1.3rem;
        font-family: 'Noto Sans', sans-serif;
    }
    
    .um {
        color: #979595;
        font-size: 1.3rem;
        font-family: 'Noto Sans', sans-serif;
        padding-right: 4%;
    }
    
    .legenda-passo1 {
        color: #514C4C;
        font-size: 1.25rem;
        font-family: 'Noto Sans', sans-serif;
        display: flex;
        width: 450px;
        flex-direction: column;
        flex-shrink: 0;
        margin-bottom: 40px;
       
    }
    
    .linha-horizontal {
        width: 540px;
        border: 1px solid #C4BABA;
        margin-left: 7%;
        margin-bottom: 50px;
    }
    
    .estimativa {
        margin-left: 7%;
        color: #000;
        font-size: 2rem;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        margin-bottom: 40px;
    
    }
    
    .passo2-legenda2 {
        display: flex;
    }
    
    .passo2 {
        margin-left: 8%;
        color: #979595;
        font-size: 1.3rem;
        font-family: 'Noto Sans', sans-serif;
    }
    
    .dois {
        color: #979595;
        font-size: 1.3rem;
        font-family: 'Noto Sans', sans-serif;
        padding-right: 4%;
        margin-left: 5px;
    }
    
    .legenda-passo2 {
        color: #514C4C;
        font-size: 1.25rem;
        font-family: 'Noto Sans', sans-serif;
        display: flex;
        margin-bottom: 40px;
        flex-direction: column;
        flex-shrink: 0;
        width: 450px;
    }
    
    .linha-horizontal2 {
        width: 540px;
        border: 1px solid #C4BABA;
        margin-left: 7%;
        margin-bottom: 50px;
    }
    
    .construcao {
        margin-left: 7%;
        color: #000;
        font-size: 2rem;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        margin-bottom: 40px;
    }
    
    .passo3-legenda3 {
        display: flex;
    }
    
    .passo3 {
        margin-left: 8%;
        color: #979595;
        font-size: 1.3rem;
        font-family: 'Noto Sans', sans-serif;
        margin-right: 3px;
    }
    
    .tres {
        color: #979595;
        font-size: 1.3rem;
        font-family: 'Noto Sans', sans-serif;
        padding-right: 4rem;
    }
    
    .legenda-passo3 {
        color: #514C4C;
        font-size: 1.25rem;
        font-family: 'Noto Sans', sans-serif;
        display: flex;
        flex-shrink: 0;
        flex-direction: column;
        margin-bottom: 40px;
        width: 410px;
    }
    .linha-horizontal3 {
        width: 540px;
        border: 1px solid #C4BABA;
        margin-left: 7%;
        margin-bottom: 60px;
    }
    .imagemCliente {
        width: 443px;
        height: 339px;
        flex-shrink: 0;
        justify-content: center;
        align-items: center;
      }
      
      .aspas {
        width: 25px;
        height: 22px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin-left: 48%;
        margin-bottom: 45.05px;
      }
      
      .fraseCliente {
        margin-left: 60px;
        margin-right: 62px;
        display: flex;
        width: 361px;
        flex-direction: column;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.375rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      
      .cliente {
        color: #000;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.375rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: flex;
        margin-left: 47%;
        margin-top: 29px;
      }
   

    .arrowLeft2 {
        margin-left: 50px;
        width: 10.6px;
        height: 22.34px;
        flex-shrink: 0;
        background-image: url(./img/seta\ 2\ esquerda.png );
      
      }
      
      .arrowRight2 {
      
        margin-right:-37px;
        width: 10.6px;
        height: 22.34px;
        flex-shrink: 0;
        margin-left: 83px;
        background-image: url(./img/seta\ 2\ direita.png );
      }
}


@media     (max-width: 768px)  {


    .header {
        display: flex;
        min-width: 100%;
        height: 77px;
        position: fixed;
    }

    nav {
        position: absolute;

        left: -800px;
        top: 0;
        z-index: 999;
        width: 100%;

        height: 1028px;
        background-color: black;
        transition: 0.2s;

    }
    .menu{
        display: grid;
           
       }

    #nav_check:checked~nav {
      left: 0;
    }

    .logo2 img {
        margin: 0;
        padding: 0;
        margin-left: 22px;
        margin-bottom: -10px;
        width: 40px;
        height: 40px;
    }

    .nayara-guimaraes-abreviacao {
        margin: 0;
        padding: 0;

        margin-left: 11px;
        color: #F6F6F6;
        font-family: 'Yeseva One', cursive;
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;



    }

    nav .mobile-logo {
        display: flex;
    }

    .mobile-logo img {
        width: 32px;
        display: flex;
        height: 31px;
        margin: 0;
        padding: 0;
        margin-left: 24px;
        margin-top: 27px;
        margin-right: 11px;

    }

    .mobile-nayara-guimaraes-aabrevicao {
        margin: 0;
        margin-top: 30px;
        color: #F6F6F6;
        display: flex;
        font-family: 'Yeseva One', cursive;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal
    }

    nav ul li a {



        margin-left: 24px;
    }

    nav ul  {
        display: block;
        padding: 0 20px;
        margin-top: 30px;
    }

    .como-funciona a:hover::after {
        margin-left: 0px;


        width: 29%;
        transition: 0.2s;
    }

    .item1 a:hover::after {
        margin-left: 0px;


        width: 11%;
    }

    .item2 a:hover::after {
        margin-left: 0px;


        width: 15%;
    }

    .item3 a:hover::after {
        margin-left: 0px;


        width: 15%;
    }

    .hamburger {
        display: block;
        margin: 0;
        width: 30px;
        height: 30px;
        margin-left: 73%;

    }
  





   

    #home {
        width: 100%;
        height: 910px;

        background: #262523;
    }

    .container-grid {
        grid-template-columns: 0;
    }

    .img-container2 {
        display: none;
    }

    .img-container {
        width: 100%;
        height: 487px;
        position: absolute;
        left: 0;
        margin-top: -30px;
        display: grid;
    }

    .frase {
        display: flex;
        width: 600px;
        flex-direction: column;
        
        color: #F6F6F6;
        font-family: 'Noto Sans', sans-serif;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        font-size: 1.45rem;
        z-index: 99;
        margin-top: 630px;
        margin-left: 4vw;
        margin-right: 4vw;
        

    }

    .frase2 {
        display: flex;
        width: 90%;
        flex-direction: column;
        color: #D7D7D7;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.37rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        left: 30px;
        margin-top: -60px;

    }

    #funciona {
        width: 100%;
        height: 1370px;
    }

    .funciona-grid {
        grid-template-columns: 0;
    }

    .titulo {
        font-size: 2rem;
        font-family: 'Montserrat', sans-serif;
        font-weight: 600;
        display: flex;
        width: 400px;
        margin: 0;
        padding: 0;
        margin-top: 26px;
        margin-left: 32px;
    }

    .legenda {
        display: flex;
        width: 480px;

        color: #000;
        font-size: 1.5rem;
        font-family: 'Noto Sans', sans-serif;
        margin: 0;
        padding: 0;
        margin-top: 8px;
        margin-left: 32px;



    }

    .img-legenda {
        width: 100%;
        height: 414px;
        margin: 0;
        flex-shrink: 0;
        padding: 0;
        margin-top: 20px;
        display: grid;
        position: absolute;

    }



    .mobile-planejamento {

        display: flex;
        margin-top: 470px;
        color: #000;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-left: 32px;
    }

    .mobile-passo1 {
        display: flex;
        margin-top: 0px;
        color: #979595;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 24px;
        margin-left: 32px;
    }
    .numero-mobile{
        color: #979595;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-left: 3px;
    }

    .mobile-legenda1-passo1 {
        display: flex;
        width: 580px;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.375rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 16px;
        margin-left: 32px;
    }

    .mobile-linha-horizontal1 {
        display: flex;
        background: #C4BABA;
        width: 568px;
        height: 1px;
        margin-top: 16px;
        margin-left: 31px;
    }

    .mobile-estimativa {
        display: flex;
        margin-top: 16px;
        margin-left: 32px;
        color: #000;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .mobile-passo2 {
        display: flex;
        color: #979595;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 24px;
        margin-left: 32px;
    }

    .mobile-legenda-passo2 {
        display: flex;
        margin-top: 16px;
        margin-left: 32px;
        width: 580px;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.375rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .mobile-linha-horizontal2 {
        display: flex;
        background: #C4BABA;
        width: 560px;
        height: 1px;
        margin-top: 16px;
        margin-left: 31px;
    }

    .mobile-construcao {
        display: flex;
        margin-top: 24px;
        margin-left: 32px;
        color: #000;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .mobile-passo3 {
        display: flex;
        margin-top: 16px;
        margin-left: 34px;
        color: #979595;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .legenda-mobile-passo3 {
        display: flex;
        margin-top: 16px;
        margin-left: 34px;
        width: 700px;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .mobile-linha-horizontal3 {
        display: flex;
        margin-top: 10px;
        margin-left: 32px;
        width: 678px;
        height: 1px;
        background: #C4BABA;
    }


    #projeto {
        width: 100%;
        height: 1150px;
    }


    .ImageTriangulo {
        display: none;




    }

    .mobile {
        width: 100%;
        display: flex;

    }

    .melhor {
        margin: 0;
        padding: 0;
        margin-top: -610px;
        margin-left: 26px;
        color: #F6F6F6;
        font-family: 'Montserrat', sans-serif;
        font-size: 2rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .frase-melhor {
        margin: 0;
        padding: 0;
        margin-top: 0px;
        margin-left: 23px;
        width: 580px;
        display: flex;
        color: #D7D7D7;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }


    .quantidade {
        margin: 0;
        padding: 0;
        margin-top: 15vh;
        margin-left: 24px;
        color: #000;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .frase-quantidade {
        margin: 0;
        padding: 0;
        margin-top: 8px;
        margin-left: 23px;
        color: #000;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .quantidade2 {
        margin: 0;
        padding: 0;
        margin-left: 280px;
        margin-top: 30px;
        color: #000;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .frase-quantidade2 {
        margin: 0;
        padding: 0;
        margin-left: 280px;
        margin-top: 8px;
        color: #000;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 0px;
    }

    .slide-container {

        position: relative;
        overflow: hidden;
        margin: 0;
        padding: 0;
        margin-top: 120px;

    }

    .slide-track {

        display: flex;
        transition: transform 0.3s ease-in-out;
    }

    .slide-track img {
        width: 390px;
        height: 234px;

        margin-right: 8px;
    }

    .left-arrow {
        opacity: 0%;

    }

    .right-arrow {
        opacity: 0%;
    }

    .input-control {
        display: flex;
        justify-content: center;
        margin-top: 31px;
        padding-bottom: 48px;
    }

    .carousel-input {
        display: inline-block;
        width: 25px;
        height: 6px;
        background-color: #D7D7D7;
        border-radius: 0%;
        margin: 0 0px;
        border: 1px solid #D7D7D7;
        transition: background-color 0.3s ease-in-out;
    }

    .carousel-input.active {
        background-color: #000;
    }

    .frase3 {
    
        margin-top: 48px;
       
        color: #000;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.625rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }




    .carouselContainer {
        position: relative;
        width: 100%;
        overflow: hidden;
        margin-top: -54.89px;

    }

    .carousel {
        width: 100%;
        overflow: hidden;

    }

    .mobileSlide {
        display: flex;
       
        
        flex-shrink: 0;
    }

    .imagemCliente {
        width: 100%;
        height: 350px;
        flex-shrink: 0;
        margin-top: 350px;
     margin-left: -2165px

    }
    .imagemCliente2 {
        width: 100%;
        height: 350px;
        flex-shrink: 0;
        margin-top: 350px;
        margin-left: -1723px;

    }
    .imagemCliente3 {
        width: 100%;
        height: 350px;
        flex-shrink: 0;
        margin-top: 350px;
        margin-left: -1923px;

    }
    .imagemCliente4 {
        width: 100%;
        height: 350px;
        flex-shrink: 0;
        margin-top: 350px;
        margin-left: -1780px;

    }
    .imagemCliente5 {
        width: 100%;
        height: 350px;
        flex-shrink: 0;
        margin-top: 350px;
        margin-left: -1675px;

    }
    .imagemCliente6 {
        width: 100%;
        height: 350px;
        flex-shrink: 0;
        margin-top: 350px;
        margin-left: -1821px;

    }
    .imagemCliente7 {
        width: 100%;
        height: 350px;
        flex-shrink: 0;
        margin-top: 350px;
        margin-left: -1956px;

    }
    .imagemCliente8 {
        width: 100%;
        height: 350px;
        flex-shrink: 0;
        margin-top: 350px;
        margin-left: -1638px;

    }

    .aspas {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 17%;
  
        margin-top: -210px;
    }
    .aspas2 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 21%;
  
        margin-top: -210px;
    }
    .aspas3 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 19%;
  
        margin-top: -210px;
    }
    .aspas4 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 21%;
  
        margin-top: -210px;
    }
    .aspas5 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 22%;
  
        margin-top: -210px;
    }
    .aspas6 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 20.5%;
  
        margin-top: -210px;
    }
    .aspas7 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 18.5%;
  
        margin-top: -210px;
    }
    .aspas8 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 22%;
  
        margin-top: -210px;
    }

    .fraseCliente {
     
        width:24%;
       display: flex;
     
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.375rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -13%;
       text-align: center;
      
        
    
    }
    .fraseCliente2 {
        margin: 0;
        width: 25%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -13%;
        margin-right: 1.7%;
       text-align: center;
        
    
    }
    .fraseCliente3 {
        margin: 0;
        width: 24%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -12%;
        margin-right: 2%;
        text-align: center;
        
    
    }
    .fraseCliente4 {
        margin: 0;
        width: 23%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -12%;
        margin-right: 3%;
        text-align: center;
        
    
    }
    .fraseCliente5 {
        margin: 0;
        width: 26%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -13%;
        margin-right: 1%;
        text-align: center;
        
    
    }
    .fraseCliente6 {
        margin: 0;
        width: 24%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -13%;
        margin-right: 2.8%;
        text-align: center;
        
    
    }
    .fraseCliente7 {
        margin: 0;
        width: 23.5%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -12.5%;
        margin-right: 2.8%;
        text-align: center;
        
    
    }
    .fraseCliente8 {
        margin: 0;
        width: 27%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -14%;
        margin-right: 0.5%;
        text-align: center;
        
    
    }

    .cliente {
        color: #000;
        font-family: 'Noto Sans', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: flex;
        margin: 0;
        margin-top: -40px;
        margin-left: -16%;
        width: 300px;

    }

    .arrows {

        top: 72%;
        opacity: 0%;
        width: 120%;
    }

    .arrowLeft2 {
        margin-left: 10px;

    }

    .arrowRight2 {

        margin-right: 160px;

        margin-left: 0px;
    }

    .inputContainer {
        display: flex;
        justify-content: center;
        margin-top: 32.27px;

    }

    .input {

        width: 50px;
        height: 6px;
        margin: 0 0px;
        background-color: #D7D7D7;
        border: 1px solid #D7D7D7;
        border-radius: 0%;
        transition: background-color 0.3s ease-in-out;
        margin-bottom: 112px;
    }

    #contatos {
        width: 100%;
        height: 600px;
    }

    .frase-contato {
       
       

        width: 50%;
        text-align: center;
       margin-left: 28%;
       margin-right:30%;
      
        font-size: 26px;
       
       
 
    }

    .frase-contato2 {
       
        margin-top: 8px;
      
        width: 60%;
        color: #000;
      margin-left: 21.5%;
        font-family: 'Noto Sans', sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .butao {
        border: 1px solid black;
        transition: border-color 0.5s, background-color 0.5s, color 0.5s;
        width: 65%;
        justify-content: center;
        padding: 15px 28px;
        flex-shrink: 0;
        margin-left: 19%;
        margin-top: -30px;
        margin-right:0;
        background-color: #000;
        gap: 10px;
        display: flex;
        cursor: pointer
    }
    
    
    .enviar {
        text-align: center;
        text-decoration: none;
        color: #ffff;
        list-style: none;
        font-family: 'Noto Sans', sans-serif;
        font-size:1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        list-style: none;
    }

    footer {
        width: 100%;
        height: 290px;
        flex-shrink: 0;
    }

    .footer-flex {
        margin: 0;

    }

    .icon-footer {
      
        width: 36px;
        height: 36px;
      
        margin-top: 24.5px;
    }

    .frase-lado-icon {
        margin: 0;
        color: #F6F6F6;
        font-family: 'Yeseva One', cursive;
        font-size: 2rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 24.5px;
        
    }

    .funcao {
        margin: 0;
        color: #FFF;
        font-family: 'Noto Sans', sans-serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      
        margin-top: 18px;
    }

    .endereco {
        margin: 0;
        color: #FFF;
        font-family: 'Noto Sans', sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
       
        margin-top: 16px;
    }

    .email-contato {
        margin: 0;
        color: #FFF;
        font-family: 'Noto Sans', sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        
        margin-top: 16px;
    }

    .telefone {
        margin: 0;
        color: #FFF;
        font-family: 'Noto Sans', sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      
        margin-top: 16px;
    }

    .insta {
        margin: 0;
        width: 35px;
        height: 35px;
        margin-top: 16px;
    
    }

    .icon-whatsapp {
        margin: 0;
        width: 35px;
        height: 35px;
        margin-top: 16px;
        margin-left: 20px;
    }

    .linkedin {
        margin: 0;
        width: 35px;
        height: 35px;
        margin-top: 16px;
        margin-left: 20px;
    }




    .direitos-reservados {
        display: none;
    }

    .planejamento {
        display: none;
    }

    .passo1 {
        display: none;
    }

    .um {
        display: none;
    }

    .legenda-passo1 {
        display: none;
    }

    .linha-horizontal {
        display: none;
    }

    .estimativa {
        display: none;
    }

    .passo2 {
        display: none;
    }

    .dois {
        display: none;
    }

    .legenda-passo2 {
        display: none;
    }

    .linha-horizontal2 {
        display: none;
    }

    .construcao {
        display: none;
    }

    .passo3 {
        display: none;
    }

    .tres {
        display: none;
    }

    .legenda-passo3 {
        display: none;
    }

    .linha-horizontal3 {
        display: none;
    }

}




@media (max-width:705px) {

    .como-funciona a:hover::after {
        margin-left: 0px;


        width: 33%;
        transition: 0.2s;
    }

    .item1 a:hover::after {
        margin-left: 0px;


        width: 13%;
    }

    .item2 a:hover::after {
        margin-left: 0px;


        width: 17%;
    }

    .item3 a:hover::after {
        margin-left: 0px;


        width: 17%;
    }


    #funciona {
        width: 100%;
        height: 1390px;
    }

    .legenda-mobile-passo3 {
        display: flex;
        margin-top: 16px;
        margin-left: 32px;
        width: 600px;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .mobile-linha-horizontal3 {
        display: flex;
        margin-top: 10px;
        margin-left: 31px;
        width: 570px;
        height: 1px;
        background: #C4BABA;
    }

    .melhor {
        margin: 0;
        padding: 0;
        margin-top: -590px;
        margin-left: 26px;
        color: #F6F6F6;
        font-family: 'Montserrat', sans-serif;
        font-size: 2rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .frase-melhor {
        margin: 0;
        padding: 0;
        margin-top: 30px;
        margin-left: 23px;
        width: 580px;
        display: flex;
        color: #D7D7D7;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }


    .quantidade {
        margin: 0;
        padding: 0;
        margin-top: 11vh;
        margin-left: 24px;
        color: #000;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .frase-quantidade {
        margin: 0;
        padding: 0;
        margin-top: 8px;
        margin-left: 23px;
        color: #000;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .quantidade2 {
        margin: 0;
        padding: 0;
        margin-left: 280px;
        margin-top: 30px;
        color: #000;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .frase-quantidade2 {
        margin: 0;
        padding: 0;
        margin-left: 280px;
        margin-top: 8px;
        color: #000;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 66px;
    }



    .slide-track img {
        width: 382px;
        height: 234px;

        margin-right: 8px;
    }


    .aspas {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
        margin-left: 15%;
  
        margin-top: -210px;
    }
    .aspas2 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 19.5%;
  
        margin-top: -210px;
    }
    .aspas3 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 17%;
  
        margin-top: -210px;
    }
    .aspas4 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 19%;
  
        margin-top: -210px;
    }
    .aspas5 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 19.5%;
  
        margin-top: -210px;
    }
    .aspas6 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 19%;
  
        margin-top: -210px;
    }
    .aspas7 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 17%;
  
        margin-top: -210px;
    }
    .aspas8 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 20.5%;
  
        margin-top: -210px;
    }

    .fraseCliente {
     
        width:24%;
       display: flex;
     
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.375rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -12.5%;
       text-align: center;
      
        
    
    }
    .fraseCliente2 {
        margin: 0;
        width: 25%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -13.5%;
        margin-right: 2%;
       text-align: center;
        
    
    }
    .fraseCliente3 {
        margin: 0;
        width: 24%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -12.5%;
        margin-right: 2.5%;
        text-align: center;
        
    
    }
    .fraseCliente4 {
        margin: 0;
        width: 23%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -12%;
        margin-right: 3%;
        text-align: center;
        
    
    }
    .fraseCliente5 {
        margin: 0;
        width: 26%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -13.5%;
        margin-right: 1.5%;
        text-align: center;
        
    
    }
    .fraseCliente6 {
        margin: 0;
        width: 24%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -13%;
        margin-right: 2.7%;
        text-align: center;
        
    
    }
    .fraseCliente7 {
        margin: 0;
        width: 23.5%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -12.5%;
        margin-right: 3%;
        text-align: center;
        
    
    }
    .fraseCliente8 {
        margin: 0;
        width: 27%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -14%;
        margin-right: 0.7%;
        text-align: center;
        
    
    }

    #contatos {
        width: 100%;
        height: 690px;
    }
    .frase-contato {
        margin-top: 720px;
    }
 

    
   
    .butao {
        border: 1px solid black;
        transition: border-color 0.5s, background-color 0.5s, color 0.5s;
        width: 65%;
        justify-content: center;
        padding: 15px 28px;
        flex-shrink: 0;
        margin-left: 19%;
        margin-top: -30px;
        margin-right:0;
        background-color: #000;
        gap: 10px;
        display: flex;
        cursor: pointer
    }
    
    
    .enviar {
        text-align: center;
        text-decoration: none;
        color: #ffff;
        list-style: none;
        font-family: 'Noto Sans', sans-serif;
        font-size:0.8rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        list-style: none;
    }
}


@media (max-width:666px){


    .melhor {
        margin: 0;
        padding: 0;
        margin-top: -535px;
        margin-left: 26px;
        color: #F6F6F6;
        font-family: 'Montserrat', sans-serif;
        font-size: 2rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .frase-melhor {
        margin: 0;
        padding: 0;
        margin-top: 30px;
        margin-left: 23px;
        width: 550px;
        display: flex;
        color: #D7D7D7;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }


    .quantidade {
        margin: 0;
        padding: 0;
        margin-top: 11vh;
        margin-left: 24px;
        color: #000;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .frase-quantidade {
        margin: 0;
        padding: 0;
        margin-top: 8px;
        margin-left: 23px;
        color: #000;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .quantidade2 {
        margin: 0;
        padding: 0;
        margin-left: 280px;
        margin-top: 30px;
        color: #000;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .frase-quantidade2 {
        margin: 0;
        padding: 0;
        margin-left: 280px;
        margin-top: 8px;
        color: #000;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 66px;
    }
   
    #funciona {
        width: 100%;
        height: 1480px;
    }
    .mobile-passo1 {
        display: flex;
        margin-top: 0px;
        color: #979595;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 24px;
        margin-left: 32px;
    }
    .numero-mobile{
        color: #979595;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-left: 3px;
    }

    .mobile-legenda1-passo1 {
        display: flex;
        width: 500px;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.375rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 16px;
        margin-left: 32px;
    }

    .mobile-linha-horizontal1 {
        display: flex;
        background: #C4BABA;
        width: 475px;
        height: 1px;
        margin-top: 16px;
        margin-left: 31px;
    }


    .mobile-passo2 {
        display: flex;
        color: #979595;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 24px;
        margin-left: 32px;
    }

    .mobile-legenda-passo2 {
        display: flex;
        margin-top: 16px;
        margin-left: 32px;
        width: 500px;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.375rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .mobile-linha-horizontal2 {
        display: flex;
        background: #C4BABA;
        width: 475px;
        height: 1px;
        margin-top: 16px;
        margin-left: 31px;
    }


    .mobile-passo3 {
        display: flex;
        margin-top: 16px;
        margin-left: 32px;
        color: #979595;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .legenda-mobile-passo3 {
        display: flex;
        margin-top: 16px;
        margin-left: 32px;
        width: 500px;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.35rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .mobile-linha-horizontal3 {
        display: flex;
        margin-top: 10px;
        margin-left: 31px;
        width: 475px;
        height: 1px;
        background: #C4BABA;
    }

    .aspas {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
        margin-left: 14%;
  
        margin-top: -210px;
    }
    .aspas2 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 18%;
  
        margin-top: -210px;
    }
    .aspas3 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 16%;
  
        margin-top: -210px;
    }
    .aspas4 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 17.5%;
  
        margin-top: -210px;
    }
    .aspas5 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 18.5%;
  
        margin-top: -210px;
    }
    .aspas6 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 17.5%;
  
        margin-top: -210px;
    }
    .aspas7 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 16%;
  
        margin-top: -210px;
    }
    .aspas8 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 18.5%;
  
        margin-top: -210px;
    }

    .fraseCliente {
     
        width:24%;
       display: flex;
     
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.375rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -12.5%;
       text-align: center;
      
        
    
    }
    .fraseCliente2 {
        margin: 0;
        width: 25%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -13.5%;
        margin-right: 2%;
       text-align: center;
        
    
    }
    .fraseCliente3 {
        margin: 0;
        width: 24%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -12.5%;
        margin-right: 2.5%;
        text-align: center;
        
    
    }
    .fraseCliente4 {
        margin: 0;
        width: 23%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -12%;
        margin-right: 3%;
        text-align: center;
        
    
    }
    .fraseCliente5 {
        margin: 0;
        width: 26%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -13.5%;
        margin-right: 1.5%;
        text-align: center;
        
    
    }
    .fraseCliente6 {
        margin: 0;
        width: 24%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -13%;
        margin-right: 2.7%;
        text-align: center;
        
    
    }
    .fraseCliente7 {
        margin: 0;
        width: 23.5%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -12.5%;
        margin-right: 3%;
        text-align: center;
        
    
    }
    .fraseCliente8 {
        margin: 0;
        width: 27%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -14%;
        margin-right: 0.7%;
        text-align: center;
        
    
    }
    .frase-contato {
        margin-top: 720px;
    }

   
    #contatos {
        width: 100%;
        height: 690px;
    }
}
@media (max-width:622px){
    .como-funciona a:hover::after {
        margin-left: 0px;


        width: 38%;
        transition: 0.2s;
    }

    .item1 a:hover::after {
        margin-left: 0px;


        width: 15%;
    }

    .item2 a:hover::after {
        margin-left: 0px;


        width: 20%;
    }

    .item3 a:hover::after {
        margin-left: 0px;


        width: 20%;
    }
    .hamburger {
        display: block;
        margin: 0;
        width: 30px;
        height: 30px;
        margin-left: 68%;

    }
    .melhor {
        margin: 0;
        padding: 0;
        margin-top: -510px;
        margin-left: 26px;
        color: #F6F6F6;
        font-family: 'Montserrat', sans-serif;
        font-size: 2rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .frase-melhor {
        margin: 0;
        padding: 0;
        margin-top: 30px;
        margin-left: 23px;
        width: 550px;
        display: flex;
        color: #D7D7D7;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }


    .quantidade {
        margin: 0;
        padding: 0;
        margin-top: 11vh;
        margin-left: 24px;
        color: #000;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .frase-quantidade {
        margin: 0;
        padding: 0;
        margin-top: 8px;
        margin-left: 23px;
        color: #000;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .quantidade2 {
        margin: 0;
        padding: 0;
        margin-left: 280px;
        margin-top: 30px;
        color: #000;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .frase-quantidade2 {
        margin: 0;
        padding: 0;
        margin-left: 280px;
        margin-top: 8px;
        color: #000;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 66px;
    }

    .imagemCliente {
        width: 100%;
        height: 350px;
        flex-shrink: 0;
        margin-top: 350px;
     margin-left: -2007px

    }
    .imagemCliente2 {
        width: 100%;
        height: 350px;
        flex-shrink: 0;
        margin-top: 350px;
        margin-left: -1723px;

    }
    .imagemCliente3 {
        width: 100%;
        height: 350px;
        flex-shrink: 0;
        margin-top: 350px;
        margin-left: -1923px;

    }
    .imagemCliente4 {
        width: 100%;
        height: 350px;
        flex-shrink: 0;
        margin-top: 350px;
        margin-left: -1780px;

    }
    .imagemCliente5 {
        width: 100%;
        height: 350px;
        flex-shrink: 0;
        margin-top: 350px;
        margin-left: -1675px;

    }
    .imagemCliente6 {
        width: 100%;
        height: 350px;
        flex-shrink: 0;
        margin-top: 350px;
        margin-left: -1821px;

    }
    .imagemCliente7 {
        width: 100%;
        height: 350px;
        flex-shrink: 0;
        margin-top: 350px;
        margin-left: -1956px;

    }
    .imagemCliente8 {
        width: 100%;
        height: 350px;
        flex-shrink: 0;
        margin-top: 350px;
        margin-left: -1638px;

    }

    .aspas {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 14.5%;
  
        margin-top: -215px;
    }
    .aspas2 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 16.5%;
  
        margin-top: -210px;
    }
    .aspas3 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 14.5%;
  
        margin-top: -210px;
    }
    .aspas4 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 16%;
  
        margin-top: -210px;
    }
    .aspas5 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 17%;
  
        margin-top: -210px;
    }
    .aspas6 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 16%;
  
        margin-top: -210px;
    }
    .aspas7 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 15%;
  
        margin-top: -210px;
    }
    .aspas8 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 18%;
  
        margin-top: -210px;
    }

    .fraseCliente {
     
        width:20%;
       display: flex;
     
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.3rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -180px;
        margin-left: -11%;
        margin-right: 5%;
       text-align: center;
      
        
    
    }
    .fraseCliente2 {
        margin: 0;
        width: 25%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -13%;
        margin-right: 1.7%;
       text-align: center;
        
    
    }
    .fraseCliente3 {
        margin: 0;
        width: 24%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -12%;
        margin-right: 2%;
        text-align: center;
        
    
    }
    .fraseCliente4 {
        margin: 0;
        width: 23%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -12%;
        margin-right: 3%;
        text-align: center;
        
    
    }
    .fraseCliente5 {
        margin: 0;
        width: 26%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -13%;
        margin-right: 1%;
        text-align: center;
        
    
    }
    .fraseCliente6 {
        margin: 0;
        width: 24%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -13%;
        margin-right: 2.8%;
        text-align: center;
        
    
    }
    .fraseCliente7 {
        margin: 0;
        width: 23.5%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -12.5%;
        margin-right: 2.8%;
        text-align: center;
        
    
    }
    .fraseCliente8 {
        margin: 0;
        width: 27%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -14%;
        margin-right: 0.5%;
        text-align: center;
        
    
    }

    .cliente {
        color: #000;
        font-family: 'Noto Sans', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: flex;
        margin: 0;
        margin-top: -40px;
        margin-left: -16%;
        width: 300px;

    }


    .frase-contato {
        margin-top: 720px;
    }

    
    #contatos {
        width: 100%;
        height: 690px;
    }




    .butao {
        border: 1px solid black;
        transition: border-color 0.5s, background-color 0.5s, color 0.5s;
        width: 70%;
        justify-content: center;
        padding: 15px 28px;
        flex-shrink: 0;
        margin-left: 17%;
        margin-top: -30px;
        margin-right:0;
        background-color: #000;
        gap: 10px;
        display: flex;
        cursor: pointer
    }
    
    
    .enviar {
        text-align: center;
        text-decoration: none;
        color: #ffff;
        list-style: none;
        font-family: 'Noto Sans', sans-serif;
        font-size:0.8rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        list-style: none;
    }
}
@media (max-width:586px){
    .melhor {
        margin: 0;
        padding: 0;
        margin-top: -480px;
        margin-left: 26px;
        color: #F6F6F6;
        font-family: 'Montserrat', sans-serif;
        font-size: 2rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .frase-melhor {
        margin: 0;
        padding: 0;
        margin-top: 30px;
        margin-left: 23px;
        width: 450px;
        display: flex;
        color: #D7D7D7;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.3rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }


    .quantidade {
        margin: 0;
        padding: 0;
        margin-top: 11vh;
        margin-left: 24px;
        color: #000;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .frase-quantidade {
        margin: 0;
        padding: 0;
        margin-top: 8px;
        margin-left: 23px;
        color: #000;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .quantidade2 {
        margin: 0;
        padding: 0;
        margin-left: 280px;
        margin-top: 30px;
        color: #000;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .frase-quantidade2 {
        margin: 0;
        padding: 0;
        margin-left: 280px;
        margin-top: 8px;
        color: #000;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 66px;
    }

    .slide-track img {
        width: 370px;
        height: 234px;

        margin-right: 8px;
    }

    .frase3 {
    
        margin-top: 48px;
       
        color: #000;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }


    .imagemCliente {
        width: 100%;
        height: 350px;
        flex-shrink: 0;
        margin-top: 350px;
     margin-left: -2007px

    }
    .imagemCliente2 {
        width: 100%;
        height: 350px;
        flex-shrink: 0;
        margin-top: 350px;
        margin-left: -1723px;

    }
    .imagemCliente3 {
        width: 100%;
        height: 350px;
        flex-shrink: 0;
        margin-top: 350px;
        margin-left: -1923px;

    }
    .imagemCliente4 {
        width: 100%;
        height: 350px;
        flex-shrink: 0;
        margin-top: 350px;
        margin-left: -1780px;

    }
    .imagemCliente5 {
        width: 100%;
        height: 350px;
        flex-shrink: 0;
        margin-top: 350px;
        margin-left: -1675px;

    }
    .imagemCliente6 {
        width: 100%;
        height: 350px;
        flex-shrink: 0;
        margin-top: 350px;
        margin-left: -1821px;

    }
    .imagemCliente7 {
        width: 100%;
        height: 350px;
        flex-shrink: 0;
        margin-top: 350px;
        margin-left: -1956px;

    }
    .imagemCliente8 {
        width: 100%;
        height: 350px;
        flex-shrink: 0;
        margin-top: 350px;
        margin-left: -1638px;

    }

    .aspas {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 14.5%;
  
        margin-top: -215px;
    }
    .aspas2 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 16.5%;
  
        margin-top: -210px;
    }
    .aspas3 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 14.5%;
  
        margin-top: -210px;
    }
    .aspas4 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 16%;
  
        margin-top: -210px;
    }
    .aspas5 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 17%;
  
        margin-top: -210px;
    }
    .aspas6 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 16%;
  
        margin-top: -210px;
    }
    .aspas7 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 15%;
  
        margin-top: -210px;
    }
    .aspas8 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 18%;
  
        margin-top: -210px;
    }

    .fraseCliente {
     
        width:20%;
       display: flex;
     
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.3rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -180px;
        margin-left: -11%;
        margin-right: 5%;
       text-align: center;
      
        
    
    }
    .fraseCliente2 {
        margin: 0;
        width: 25%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -13%;
        margin-right: 1.7%;
       text-align: center;
        
    
    }
    .fraseCliente3 {
        margin: 0;
        width: 24%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -12%;
        margin-right: 2%;
        text-align: center;
        
    
    }
    .fraseCliente4 {
        margin: 0;
        width: 23%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -12%;
        margin-right: 3%;
        text-align: center;
        
    
    }
    .fraseCliente5 {
        margin: 0;
        width: 26%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -13%;
        margin-right: 1%;
        text-align: center;
        
    
    }
    .fraseCliente6 {
        margin: 0;
        width: 24%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -13%;
        margin-right: 2.8%;
        text-align: center;
        
    
    }
    .fraseCliente7 {
        margin: 0;
        width: 23.5%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -12.5%;
        margin-right: 2.8%;
        text-align: center;
        
    
    }
    .fraseCliente8 {
        margin: 0;
        width: 27%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -14%;
        margin-right: 0.5%;
        text-align: center;
        
    
    }

    .cliente {
        color: #000;
        font-family: 'Noto Sans', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: flex;
        margin: 0;
        margin-top: -40px;
        margin-left: -16%;
        width: 300px;

    }

    #contatos {
        width: 100%;
        height: 650px;
    }

.frase-contato{
    margin-top: 650px;
}



    .butao {
        border: 1px solid black;
        transition: border-color 0.5s, background-color 0.5s, color 0.5s;
        width: 75%;
        justify-content: center;
        padding: 15px 28px;
        flex-shrink: 0;
        margin-left: 14.5%;
        margin-top: -30px;
        margin-right:0;
        background-color: #000;
        gap: 10px;
        display: flex;
        cursor: pointer
    }
    
    
    .enviar {
        text-align: center;
        text-decoration: none;
        color: #ffff;
        list-style: none;
        font-family: 'Noto Sans', sans-serif;
        font-size:0.8rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        list-style: none;
    }
}
@media (max-width:550px){
    .como-funciona a:hover::after {
        margin-left: 0px;


        width: 43%;
        transition: 0.2s;
    }

    .item1 a:hover::after {
        margin-left: 0px;


        width: 17%;
    }

    .item2 a:hover::after {
        margin-left: 0px;


        width: 23%;
    }

    .item3 a:hover::after {
        margin-left: 0px;


        width: 22%;
    }
    #home {
        width: 100%;
        height: 950px;

        background: #262523;
    }

   

    .frase {
        display: flex;
        width: 400px;
        flex-direction: column;
        
        color: #F6F6F6;
        font-family: 'Noto Sans', sans-serif;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        font-size: 1.425rem;
        z-index: 99;
        margin-top: 630px;
        margin-left: 4vw;
        margin-right: 4vw;
        

    }
    #funciona {
        width: 100%;
        height: 1520px;
    }
    .mobile-passo1 {
        display: flex;
        margin-top: 0px;
        color: #979595;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 24px;
        margin-left: 32px;
    }
    .numero-mobile{
        color: #979595;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-left: 3px;
    }

    .mobile-legenda1-passo1 {
        display: flex;
        width: 430px;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.3rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 16px;
        margin-left: 32px;
    }

    .mobile-linha-horizontal1 {
        display: flex;
        background: #C4BABA;
        width: 380px;
        height: 1px;
        margin-top: 16px;
        margin-left: 31px;
    }


    .mobile-passo2 {
        display: flex;
        color: #979595;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 24px;
        margin-left: 32px;
    }

    .mobile-legenda-passo2 {
        display: flex;
        margin-top: 16px;
        margin-left: 32px;
        width: 430px;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.3rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .mobile-linha-horizontal2 {
        display: flex;
        background: #C4BABA;
        width: 380px;
        height: 1px;
        margin-top: 16px;
        margin-left: 31px;
    }


    .mobile-passo3 {
        display: flex;
        margin-top: 16px;
        margin-left: 32px;
        color: #979595;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .legenda-mobile-passo3 {
        display: flex;
        margin-top: 16px;
        margin-left: 32px;
        width: 410px;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.3rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .mobile-linha-horizontal3 {
        display: flex;
        margin-top: 10px;
        margin-left: 31px;
        width: 380px;
        height: 1px;
        background: #C4BABA;
    }
    
    .melhor {
        margin: 0;
        padding: 0;
        margin-top: -440px;
        margin-left: 26px;
        color: #F6F6F6;
        font-family: 'Montserrat', sans-serif;
        font-size: 2rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .frase-melhor {
        margin: 0;
        padding: 0;
        margin-top: 30px;
        margin-left: 23px;
        width: 450px;
        display: flex;
        color: #D7D7D7;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.3rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }


    .quantidade {
        margin: 0;
        padding: 0;
        margin-top: 10vh;
        margin-left: 24px;
        color: #000;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .frase-quantidade {
        margin: 0;
        padding: 0;
        margin-top: 8px;
        margin-left: 23px;
        color: #000;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .quantidade2 {
        margin: 0;
        padding: 0;
        margin-left: 280px;
        margin-top: 30px;
        color: #000;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .frase-quantidade2 {
        margin: 0;
        padding: 0;
        margin-left: 280px;
        margin-top: 8px;
        color: #000;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 66px;
    }

    .slide-track img {
        width: 369px;
        height: 234px;

        margin-right: 8px;
    }

    .frase3 {
    
        margin-top: 48px;
       
        color: #000;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }


    .imagemCliente {
        width: 100%;
        height: 350px;
        flex-shrink: 0;
        margin-top: 350px;
     margin-left: -1878px

    }
    .imagemCliente2 {
        width: 100%;
        height: 350px;
        flex-shrink: 0;
        margin-top: 350px;
        margin-left: -1546px;

    }
    .imagemCliente3 {
        width: 100%;
        height: 350px;
        flex-shrink: 0;
        margin-top: 350px;
        margin-left: -1724px;

    }
    .imagemCliente4 {
        width: 100%;
        height: 350px;
        flex-shrink: 0;
        margin-top: 350px;
        margin-left: -1595px;

    }
    .imagemCliente5 {
        width: 100%;
        height: 350px;
        flex-shrink: 0;
        margin-top: 350px;
        margin-left: -1504px;

    }
    .imagemCliente6 {
        width: 100%;
        height: 350px;
        flex-shrink: 0;
        margin-top: 350px;
        margin-left: -1632px;

    }
    .imagemCliente7 {
        width: 100%;
        height: 350px;
        flex-shrink: 0;
        margin-top: 350px;
        margin-left: -1750px;

    }
    .imagemCliente8 {
        width: 100%;
        height: 350px;
        flex-shrink: 0;
        margin-top: 350px;
        margin-left: -1472px;

    }

    .aspas {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 13.5%;
  
        margin-top: -215px;
    }
    .aspas2 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 16%;
  
        margin-top: -210px;
    }
    .aspas3 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 14%;
  
        margin-top: -210px;
    }
    .aspas4 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 16%;
  
        margin-top: -210px;
    }
    .aspas5 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 16%;
  
        margin-top: -210px;
    }
    .aspas6 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 16%;
  
        margin-top: -210px;
    }
    .aspas7 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 14%;
  
        margin-top: -210px;
    }
    .aspas8 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 16.5%;
  
        margin-top: -210px;
    }

    .fraseCliente {
     
        width:20%;
       display: flex;
     
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -180px;
        margin-left: -11%;
        margin-right: 5%;
       text-align: center;
      
        
    
    }
    .fraseCliente2 {
        margin: 0;
        width: 24%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -13%;
        margin-right: 2.2%;
       text-align: center;
        
    
    }
    .fraseCliente3 {
        margin: 0;
        width: 22%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -12%;
        margin-right: 3.7%;
        text-align: center;
        
    
    }
    .fraseCliente4 {
        margin: 0;
        width: 23%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -12.2%;
        margin-right: 2.8%;
        text-align: center;
        
    
    }
    .fraseCliente5 {
        margin: 0;
        width: 23%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -11.8%;
        margin-right: 2.8%;
        text-align: center;
        
    
    }
    .fraseCliente6 {
        margin: 0;
        width: 22%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -12.5%;
        margin-right: 4%;
        text-align: center;
        
    
    }
    .fraseCliente7 {
        margin: 0;
        width: 22%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size:1.2rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -11.5%;
        margin-right: 3.2%;
        text-align: center;
        
    
    }
    .fraseCliente8 {
        margin: 0;
        width: 27%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size:1.2rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -14.2%;
        margin-right: 0.5%;
        text-align: center;
        
    
    }

    .cliente {
        color: #000;
        font-family: 'Noto Sans', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: flex;
        margin: 0;
        margin-top: -40px;
        margin-left: -16%;
        width: 300px;

    }







    .butao {
        border: 1px solid black;
        transition: border-color 0.5s, background-color 0.5s, color 0.5s;
        width: 90%;
        justify-content: center;
        padding: 15px 28px;
        flex-shrink: 0;
        margin-left:5%;
        margin-top: -60px;
        margin-right:0;
        background-color: #000;
        gap: 10px;
        display: flex;
        cursor: pointer
    }
    
    
    .enviar {
        text-align: center;
        text-decoration: none;
        color: #ffff;
        list-style: none;
        font-family: 'Noto Sans', sans-serif;
        font-size:0.8rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        list-style: none;
    }
    #projeto{
        width: 100%;
        height: 1100px;
    }
}
@media (max-width:500px){
    .como-funciona a:hover::after {
        margin-left: 0px;


        width: 48%;
        transition: 0.2s;
    }

    .item1 a:hover::after {
        margin-left: 0px;


        width: 19%;
    }

    .item2 a:hover::after {
        margin-left: 0px;


        width: 25%;
    }

    .item3 a:hover::after {
        margin-left: 0px;


        width: 25%;
    }
    .hamburger {
        display: block;
        margin: 0;
        width: 30px;
        height: 30px;
        margin-left: 60%;

    }
    #home {
        width: 100%;
        height: 950px;

        background: #262523;
    }

   

    .frase {
        display: flex;
        width: 400px;
        flex-direction: column;
        
        color: #F6F6F6;
        font-family: 'Noto Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        font-size: 1.4rem;
        z-index: 99;
        margin-top: 630px;
        margin-left: 4vw;
        margin-right: 4vw;
        

    }
    #funciona {
        width: 100%;
        height: 1520px;
    }
    .titulo {
        font-size: 1.7rem;
        font-family: 'Montserrat', sans-serif;
        font-weight: 600;
        display: flex;
        width: 400px;
        margin: 0;
        padding: 0;
        margin-top: 26px;
        margin-left: 32px;
    }

    .legenda {
        display: flex;
        width: 450px;

        color: #000;
        font-size: 1.1rem;
        font-family: 'Noto Sans', sans-serif;
        margin: 0;
        padding: 0;
        margin-top: 8px;
        margin-left: 32px;



    }
    .mobile-passo1 {
        display: flex;
        margin-top: 0px;
        color: #979595;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 24px;
        margin-left: 32px;
    }
    .numero-mobile{
        color: #979595;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-left: 3px;
    }

    .mobile-legenda1-passo1 {
        display: flex;
        width: 430px;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.3rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 16px;
        margin-left: 32px;
    }

    .mobile-linha-horizontal1 {
        display: flex;
        background: #C4BABA;
        width: 380px;
        height: 1px;
        margin-top: 16px;
        margin-left: 31px;
    }


    .mobile-passo2 {
        display: flex;
        color: #979595;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 24px;
        margin-left: 32px;
    }

    .mobile-legenda-passo2 {
        display: flex;
        margin-top: 16px;
        margin-left: 32px;
        width: 430px;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.3rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .mobile-linha-horizontal2 {
        display: flex;
        background: #C4BABA;
        width: 380px;
        height: 1px;
        margin-top: 16px;
        margin-left: 31px;
    }


    .mobile-passo3 {
        display: flex;
        margin-top: 16px;
        margin-left: 32px;
        color: #979595;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .legenda-mobile-passo3 {
        display: flex;
        margin-top: 16px;
        margin-left: 32px;
        width: 410px;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.3rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .mobile-linha-horizontal3 {
        display: flex;
        margin-top: 10px;
        margin-left: 31px;
        width: 380px;
        height: 1px;
        background: #C4BABA;
    }
    
    .melhor {
        margin: 0;
        padding: 0;
        margin-top: -420px;
        margin-left: 26px;
        color: #F6F6F6;
        font-family: 'Montserrat', sans-serif;
        font-size: 2rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .frase-melhor {
        margin: 0;
        padding: 0;
        margin-top: 30px;
        margin-left: 23px;
        width: 420px;
        display: flex;
        color: #D7D7D7;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.3rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }


    .quantidade {
        margin: 0;
        padding: 0;
        margin-top: 10vh;
        margin-left: 24px;
        color: #000;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .frase-quantidade {
        margin: 0;
        padding: 0;
        margin-top: 8px;
        margin-left: 23px;
        color: #000;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .quantidade2 {
        margin: 0;
        padding: 0;
        margin-left: 280px;
        margin-top: 30px;
        color: #000;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .frase-quantidade2 {
        margin: 0;
        padding: 0;
        margin-left: 280px;
        margin-top: 8px;
        color: #000;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 66px;
    }

    .slide-track img {
        width: 369px;
        height: 234px;

        margin-right: 8px;
    }

    .frase3 {
    
        margin-top: 48px;
       
        color: #000;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }


    .imagemCliente {
        width: 100%;
        height: 350px;
        flex-shrink: 0;
        margin-top: 350px;
     margin-left: -1619px

    }
    .imagemCliente2 {
        width: 100%;
        height: 350px;
        flex-shrink: 0;
        margin-top: 350px;
        margin-left: -1342px;

    }
    .imagemCliente3 {
        width: 100%;
        height: 350px;
        flex-shrink: 0;
        margin-top: 350px;
        margin-left: -1487px;

    }
    .imagemCliente4 {
        width: 100%;
        height: 350px;
        flex-shrink: 0;
        margin-top: 350px;
        margin-left: -1384px;

    }
    .imagemCliente5 {
        width: 100%;
        height: 350px;
        flex-shrink: 0;
        margin-top: 350px;
        margin-left: -1307px;

    }
    .imagemCliente6 {
        width: 100%;
        height: 350px;
        flex-shrink: 0;
        margin-top: 350px;
        margin-left: -1414px;

    }
    .imagemCliente7 {
        width: 100%;
        height: 350px;
        flex-shrink: 0;
        margin-top: 350px;
        margin-left: -1512px;

    }
    .imagemCliente8 {
        width: 100%;
        height: 350px;
        flex-shrink: 0;
        margin-top: 350px;
        margin-left: -1282px;

    }

    .aspas {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 13.8%;
  
        margin-top: -215px;
    }
    .aspas2 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 17%;
  
        margin-top: -210px;
    }
    .aspas3 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 15.5%;
  
        margin-top: -210px;
    }
    .aspas4 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 16.5%;
  
        margin-top: -210px;
    }
    .aspas5 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 17%;
  
        margin-top: -210px;
    }
    .aspas6 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 17.5%;
  
        margin-top: -210px;
    }
    .aspas7 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 15%;
  
        margin-top: -210px;
    }
    .aspas8 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 17.5%;
  
        margin-top: -210px;
    }

    .fraseCliente {
     
        width:20%;
       display: flex;
     
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -180px;
        margin-left: -11%;
        margin-right: 5%;
       text-align: center;
      
        
    
    }
    .fraseCliente2 {
        margin: 0;
        width: 24%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -13%;
        margin-right: 2.2%;
       text-align: center;
        
    
    }
    .fraseCliente3 {
        margin: 0;
        width: 22%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -12%;
        margin-right: 3.3%;
        text-align: center;
        
    
    }
    .fraseCliente4 {
        margin: 0;
        width: 23%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -12.2%;
        margin-right: 2.5%;
        text-align: center;
        
    
    }
    .fraseCliente5 {
        margin: 0;
        width: 23%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -11.8%;
        margin-right: 2.5%;
        text-align: center;
        
    
    }
    .fraseCliente6 {
        margin: 0;
        width: 22%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -12.5%;
        margin-right: 4%;
        text-align: center;
        
    
    }
    .fraseCliente7 {
        margin: 0;
        width: 22%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size:1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -11.5%;
        margin-right: 3.2%;
        text-align: center;
        
    
    }
    .fraseCliente8 {
        margin: 0;
        width: 27%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size:1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -14.2%;
        margin-right: 0.5%;
        text-align: center;
        
    
    }

    .cliente {
        color: #000;
        font-family: 'Noto Sans', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: flex;
        margin: 0;
        margin-top: -40px;
        margin-left: -16%;
        width: 300px;

    }







    .butao {
        border: 1px solid black;
        transition: border-color 0.5s, background-color 0.5s, color 0.5s;
        width: 90%;
        justify-content: center;
        padding: 15px 28px;
        flex-shrink: 0;
        margin-left:5%;
        margin-top: -60px;
        margin-right:0;
        background-color: #000;
        gap: 10px;
        display: flex;
        cursor: pointer
    }
    
    
    .enviar {
        text-align: center;
        text-decoration: none;
        color: #ffff;
        list-style: none;
        font-family: 'Noto Sans', sans-serif;
        font-size:0.8rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        list-style: none;
    }
    #contatos {
        width: 100%;
        height: 690px;
    }
    #projeto{
        width: 100%;
        height: 1000px;
    }
}
@media (max-width:460px){
    .como-funciona a:hover::after {
        margin-left: 0px;


        width: 53%;
        transition: 0.2s;
    }

    .item1 a:hover::after {
        margin-left: 0px;


        width: 21%;
    }

    .item2 a:hover::after {
        margin-left: 0px;


        width: 28%;
    }

    .item3 a:hover::after {
        margin-left: 0px;


        width: 27%;
    }
    .hamburger {
        display: block;
        margin: 0;
        width: 30px;
        height: 30px;
        margin-left: 60%;

    }
    #home {
        width: 100%;
        height: 1000px;

        background: #262523;
    }

   

    .frase {
        display: flex;
        width: 400px;
        flex-direction: column;
        
        color: #F6F6F6;
        font-family: 'Noto Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        font-size: 1.4rem;
        z-index: 99;
        margin-top: 630px;
        margin-left: 4vw;
        margin-right: 4vw;
        

    }
    #funciona {
        width: 100%;
        height: 1520px;
    }
    .titulo {
        font-size: 1.7rem;
        font-family: 'Montserrat', sans-serif;
        font-weight: 600;
        display: flex;
        width: 400px;
        margin: 0;
        padding: 0;
        margin-top: 26px;
        margin-left: 32px;
    }

    .legenda {
        display: flex;
        width: 450px;

        color: #000;
        font-size: 1.1rem;
        font-family: 'Noto Sans', sans-serif;
        margin: 0;
        padding: 0;
        margin-top: 8px;
        margin-left: 32px;



    }
    .mobile-passo1 {
        display: flex;
        margin-top: 0px;
        color: #979595;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 24px;
        margin-left: 32px;
    }
    .numero-mobile{
        color: #979595;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-left: 3px;
    }

    .mobile-legenda1-passo1 {
        display: flex;
        width: 350px;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 16px;
        margin-left: 32px;
    }

    .mobile-linha-horizontal1 {
        display: flex;
        background: #C4BABA;
        width: 330px;
        height: 1px;
        margin-top: 16px;
        margin-left: 31px;
    }


    .mobile-passo2 {
        display: flex;
        color: #979595;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 24px;
        margin-left: 32px;
    }

    .mobile-legenda-passo2 {
        display: flex;
        margin-top: 16px;
        margin-left: 32px;
        width: 350px;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .mobile-linha-horizontal2 {
        display: flex;
        background: #C4BABA;
        width: 330px;
        height: 1px;
        margin-top: 16px;
        margin-left: 31px;
    }


    .mobile-passo3 {
        display: flex;
        margin-top: 16px;
        margin-left: 32px;
        color: #979595;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .legenda-mobile-passo3 {
        display: flex;
        margin-top: 16px;
        margin-left: 32px;
        width: 350px;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .mobile-linha-horizontal3 {
        display: flex;
        margin-top: 10px;
        margin-left: 31px;
        width: 330px;
        height: 1px;
        background: #C4BABA;
    }
    
    .melhor {
        margin: 0;
        padding: 0;
        margin-top: -380px;
        margin-left: 26px;
        color: #F6F6F6;
        font-family: 'Montserrat', sans-serif;
        font-size: 1.8rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .frase-melhor {
        margin: 0;
        padding: 0;
        margin-top: 30px;
        margin-left: 23px;
        width: 380px;
        display: flex;
        color: #D7D7D7;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }


    .quantidade {
        margin: 0;
        padding: 0;
        margin-top: 12vh;
        margin-left: 24px;
        color: #000;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .frase-quantidade {
        margin: 0;
        padding: 0;
        margin-top: 8px;
        margin-left: 23px;
        color: #000;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .quantidade2 {
        margin: 0;
        padding: 0;
        margin-left: 280px;
        margin-top: 30px;
        color: #000;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .frase-quantidade2 {
        margin: 0;
        padding: 0;
        margin-left: 280px;
        margin-top: 8px;
        color: #000;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 66px;
    }

    .slide-track img {
        width: 361px;
        height: 234px;

        margin-right: 8px;
    }

    .frase3 {
    
        margin-top: 48px;
       
        color: #000;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }


    .imagemCliente {
        width: 100%;
        height: 350px;
        flex-shrink: 0;
        margin-top: 350px;
     margin-left: -1619px

    }
    .imagemCliente2 {
        width: 100%;
        height: 350px;
        flex-shrink: 0;
        margin-top: 350px;
        margin-left: -1342px;

    }
    .imagemCliente3 {
        width: 100%;
        height: 350px;
        flex-shrink: 0;
        margin-top: 350px;
        margin-left: -1487px;

    }
    .imagemCliente4 {
        width: 100%;
        height: 350px;
        flex-shrink: 0;
        margin-top: 350px;
        margin-left: -1384px;

    }
    .imagemCliente5 {
        width: 100%;
        height: 350px;
        flex-shrink: 0;
        margin-top: 350px;
        margin-left: -1307px;

    }
    .imagemCliente6 {
        width: 100%;
        height: 350px;
        flex-shrink: 0;
        margin-top: 350px;
        margin-left: -1414px;

    }
    .imagemCliente7 {
        width: 100%;
        height: 350px;
        flex-shrink: 0;
        margin-top: 350px;
        margin-left: -1512px;

    }
    .imagemCliente8 {
        width: 100%;
        height: 350px;
        flex-shrink: 0;
        margin-top: 350px;
        margin-left: -1282px;

    }

    .aspas {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 13%;
  
        margin-top: -215px;
    }
    .aspas2 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 15.8%;
  
        margin-top: -210px;
    }
    .aspas3 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 14.8%;
  
        margin-top: -210px;
    }
    .aspas4 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 15.6%;
  
        margin-top: -210px;
    }
    .aspas5 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 15.8%;
  
        margin-top: -210px;
    }
    .aspas6 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 15.8%;
  
        margin-top: -210px;
    }
    .aspas7 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 13.8%;
  
        margin-top: -210px;
    }
    .aspas8 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 16.2%;
  
        margin-top: -210px;
    }

    .fraseCliente {
     
        width:20%;
       display: flex;
     
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -180px;
        margin-left: -11%;
        margin-right: 4.5%;
       text-align: center;
      
        
    
    }
    .fraseCliente2 {
        margin: 0;
        width: 24%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -13%;
        margin-right: 2%;
       text-align: center;
        
    
    }
    .fraseCliente3 {
        margin: 0;
        width: 22%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -12%;
        margin-right: 3.5%;
        text-align: center;
        
    
    }
    .fraseCliente4 {
        margin: 0;
        width: 23%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -12.2%;
        margin-right: 2.5%;
        text-align: center;
        
    
    }
    .fraseCliente5 {
        margin: 0;
        width: 23%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -11.8%;
        margin-right: 2.3%;
        text-align: center;
        
    
    }
    .fraseCliente6 {
        margin: 0;
        width: 22%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -12.5%;
        margin-right: 3.9%;
        text-align: center;
        
    
    }
    .fraseCliente7 {
        margin: 0;
        width: 22%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size:1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -11.5%;
        margin-right: 3%;
        text-align: center;
        
    
    }
    .fraseCliente8 {
        margin: 0;
        width: 27%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size:1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -14.2%;
        margin-right: 0.4%;
        text-align: center;
        
    
    }

    .cliente {
        color: #000;
        font-family: 'Noto Sans', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: flex;
        margin: 0;
        margin-top: -40px;
        margin-left: -16%;
        width: 300px;

    }







    .butao {
        border: 1px solid black;
        transition: border-color 0.5s, background-color 0.5s, color 0.5s;
        width: 98%;
        justify-content: center;
        padding: 15px 28px;
        flex-shrink: 0;
        margin-left:1%;
        margin-top: -60px;
        margin-right:0;
        background-color: #000;
        gap: 10px;
        display: flex;
        cursor: pointer
    }
    
    
    .enviar {
        text-align: center;
        text-decoration: none;
        color: #ffff;
        list-style: none;
        font-family: 'Noto Sans', sans-serif;
        font-size:0.8rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        list-style: none;
    }

    .frase-contato {
       
       

        width: 80%;
        text-align: center;
       margin-left: 12%;
       margin-right:30%;
      
        font-size: 26px;
     
        margin-top: 750px;
    }

    .frase-contato2 {
       
        margin-top: 8px;
      
        width: 80%;
        color: #000;
      margin-left: 10.8%;
        font-family: 'Noto Sans', sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    #contatos {
        width: 100%;
        height: 690px;
    }
    #projeto{
        width: 100%;
        height: 1000px;
    }
}
@media (max-width:430px) {
    .como-funciona a:hover::after {
        margin-left: 0px;


        width: 57%;
        transition: 0.2s;
    }

    .item1 a:hover::after {
        margin-left: 0px;


        width: 23%;
    }

    .item2 a:hover::after {
        margin-left: 0px;


        width: 30%;
    }

    .item3 a:hover::after {
        margin-left: 0px;


        width: 29%;
    }
    .hamburger {
        display: block;
        margin: 0;
        width: 30px;
        height: 30px;
        margin-left:56%;

    }
    #home {
        width: 100%;
        height: 1000px;

        background: #262523;
    }

   

    .frase {
        display: flex;
        width: 400px;
        flex-direction: column;
        
        color: #F6F6F6;
        font-family: 'Noto Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        font-size: 1.4rem;
        z-index: 99;
        margin-top: 630px;
        margin-left: 4vw;
        margin-right: 4vw;
        

    }
    .frase2 {
        display: flex;
        width: 90%;
        flex-direction: column;
        color: #D7D7D7;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.3rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        left: 30px;
        margin-top: -55px;

    }
    #funciona {
        width: 100%;
        height: 1520px;
    }
    .titulo {
        font-size: 1.6rem;
        font-family: 'Montserrat', sans-serif;
        font-weight: 600;
        display: flex;
        width: 400px;
        margin: 0;
        padding: 0;
        margin-top: 26px;
        margin-left: 32px;
    }

    .legenda {
        display: flex;
        width: 400px;

        color: #000;
        font-size: 1.05rem;
        font-family: 'Noto Sans', sans-serif;
        margin: 0;
        padding: 0;
        margin-top: 8px;
        margin-left: 32px;



    }
    .mobile-passo1 {
        display: flex;
        margin-top: 0px;
        color: #979595;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 24px;
        margin-left: 32px;
    }
    .numero-mobile{
        color: #979595;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-left: 3px;
    }

    .mobile-legenda1-passo1 {
        display: flex;
        width: 350px;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 16px;
        margin-left: 32px;
    }

    .mobile-linha-horizontal1 {
        display: flex;
        background: #C4BABA;
        width: 330px;
        height: 1px;
        margin-top: 16px;
        margin-left: 31px;
    }


    .mobile-passo2 {
        display: flex;
        color: #979595;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 24px;
        margin-left: 32px;
    }

    .mobile-legenda-passo2 {
        display: flex;
        margin-top: 16px;
        margin-left: 32px;
        width: 350px;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .mobile-linha-horizontal2 {
        display: flex;
        background: #C4BABA;
        width: 330px;
        height: 1px;
        margin-top: 16px;
        margin-left: 31px;
    }


    .mobile-passo3 {
        display: flex;
        margin-top: 16px;
        margin-left: 32px;
        color: #979595;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .legenda-mobile-passo3 {
        display: flex;
        margin-top: 16px;
        margin-left: 32px;
        width: 350px;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .mobile-linha-horizontal3 {
        display: flex;
        margin-top: 10px;
        margin-left: 31px;
        width: 330px;
        height: 1px;
        background: #C4BABA;
    }
    
    .melhor {
        margin: 0;
        padding: 0;
        margin-top: -350px;
        margin-left: 26px;
        color: #F6F6F6;
        font-family: 'Montserrat', sans-serif;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .frase-melhor {
        margin: 0;
        padding: 0;
        margin-top: 20px;
        margin-left: 23px;
        width: 380px;
        display: flex;
        color: #D7D7D7;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }


    .quantidade {
        margin: 0;
        padding: 0;
        margin-top: 10vh;
        margin-left: 24px;
        color: #000;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .frase-quantidade {
        margin: 0;
        padding: 0;
        margin-top: 8px;
        margin-left: 23px;
        color: #000;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .quantidade2 {
        margin: 0;
        padding: 0;
        margin-left: 280px;
        margin-top: 30px;
        color: #000;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .frase-quantidade2 {
        margin: 0;
        padding: 0;
        margin-left: 280px;
        margin-top: 8px;
        color: #000;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 66px;
    }

    .slide-track img {
        width: 359px;
        height: 234px;

        margin-right: 8px;
    }

    .frase3 {
    
        margin-top: 48px;
       
        color: #000;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.1rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }


    .imagemCliente {
        width: 100%;
        height: 350px;
        flex-shrink: 0;
        margin-top: 350px;
     margin-left: -1619px

    }
    .imagemCliente2 {
        width: 100%;
        height: 350px;
        flex-shrink: 0;
        margin-top: 350px;
        margin-left: -1342px;

    }
    .imagemCliente3 {
        width: 100%;
        height: 350px;
        flex-shrink: 0;
        margin-top: 350px;
        margin-left: -1487px;

    }
    .imagemCliente4 {
        width: 100%;
        height: 350px;
        flex-shrink: 0;
        margin-top: 350px;
        margin-left: -1384px;

    }
    .imagemCliente5 {
        width: 100%;
        height: 350px;
        flex-shrink: 0;
        margin-top: 350px;
        margin-left: -1307px;

    }
    .imagemCliente6 {
        width: 100%;
        height: 350px;
        flex-shrink: 0;
        margin-top: 350px;
        margin-left: -1414px;

    }
    .imagemCliente7 {
        width: 100%;
        height: 350px;
        flex-shrink: 0;
        margin-top: 350px;
        margin-left: -1512px;

    }
    .imagemCliente8 {
        width: 100%;
        height: 350px;
        flex-shrink: 0;
        margin-top: 350px;
        margin-left: -1282px;

    }

    .aspas {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 12%;
  
        margin-top: -215px;
    }
    .aspas2 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 14.8%;
  
        margin-top: -210px;
    }
    .aspas3 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 13.8%;
  
        margin-top: -210px;
    }
    .aspas4 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 14.6%;
  
        margin-top: -210px;
    }
    .aspas5 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 14.8%;
  
        margin-top: -210px;
    }
    .aspas6 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 14.8%;
  
        margin-top: -210px;
    }
    .aspas7 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 12.9%;
  
        margin-top: -210px;
    }
    .aspas8 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 15.8%;
  
        margin-top: -210px;
    }

    .fraseCliente {
     
        width:20%;
       display: flex;
     
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -180px;
        margin-left: -11%;
        margin-right: 4.5%;
       text-align: center;
      
        
    
    }
    .fraseCliente2 {
        margin: 0;
        width: 24%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -13%;
        margin-right: 2%;
       text-align: center;
        
    
    }
    .fraseCliente3 {
        margin: 0;
        width: 22%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -12%;
        margin-right: 3.5%;
        text-align: center;
        
    
    }
    .fraseCliente4 {
        margin: 0;
        width: 23%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -12.2%;
        margin-right: 2.5%;
        text-align: center;
        
    
    }
    .fraseCliente5 {
        margin: 0;
        width: 23%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -11.8%;
        margin-right: 2.3%;
        text-align: center;
        
    
    }
    .fraseCliente6 {
        margin: 0;
        width: 22%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -12.5%;
        margin-right: 3.9%;
        text-align: center;
        
    
    }
    .fraseCliente7 {
        margin: 0;
        width: 22%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size:1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -11.5%;
        margin-right: 3%;
        text-align: center;
        
    
    }
    .fraseCliente8 {
        margin: 0;
        width: 27%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size:1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -14.2%;
        margin-right: 0.4%;
        text-align: center;
        
    
    }

    .cliente {
        color: #000;
        font-family: 'Noto Sans', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: flex;
        margin: 0;
        margin-top: -40px;
        margin-left: -16%;
        width: 300px;

    }







    .butao {
        border: 1px solid black;
        transition: border-color 0.5s, background-color 0.5s, color 0.5s;
        width: 100%;
        justify-content: center;
        padding: 15px 28px;
        flex-shrink: 0;
        margin-left:0%;
        margin-top: -60px;
        margin-right:0;
        background-color: #000;
        gap: 10px;
        display: flex;
        cursor: pointer
    }
    
    
    .enviar {
        text-align: center;
        text-decoration: none;
        color: #ffff;
        list-style: none;
        font-family: 'Noto Sans', sans-serif;
        font-size:0.8rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        list-style: none;
    }

    .frase-contato {
       
       

        width: 80%;
        text-align: center;
       margin-left: 8.5%;
       margin-right:30%;
      
        font-size: 26px;
       
       
 
    }

    .frase-contato2 {
       
        margin-top: 8px;
      
        width: 80%;
        color: #000;
      margin-left: 8.8%;
        font-family: 'Noto Sans', sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    #contatos {
        width: 100%;
        height: 690px;
    }
    #projeto{
        width: 100%;
        height: 1000px;
    }
}
@media (max-width:414px){
    .como-funciona a:hover::after {
        margin-left: 0px;


        width: 60%;
        transition: 0.2s;
    }

    .item1 a:hover::after {
        margin-left: 0px;


        width: 24%;
    }

    .item2 a:hover::after {
        margin-left: 0px;


        width: 32%;
    }

    .item3 a:hover::after {
        margin-left: 0px;


        width: 31%;
    }
    .aspas {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 12%;
  
        margin-top: -215px;
    }
    .aspas2 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 14.5%;
  
        margin-top: -210px;
    }
    .aspas3 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 13%;
  
        margin-top: -210px;
    }
    .aspas4 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 14%;
  
        margin-top: -210px;
    }
    .aspas5 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 14.8%;
  
        margin-top: -210px;
    }
    .aspas6 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 13.2%;
  
        margin-top: -210px;
    }
    .aspas7 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 12.5%;
  
        margin-top: -210px;
    }
    .aspas8 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 15.3%;
  
        margin-top: -210px;
    }

    .fraseCliente {
     
        width:17%;
       display: flex;
     
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -180px;
        margin-left: -9.8%;
        margin-right: 6.5%;
       text-align: center;
      
        
    
    }
    .fraseCliente2 {
        margin: 0;
        width: 19%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -11%;
        margin-right: 4.9%;
       text-align: center;
        
    
    }
    .fraseCliente3 {
        margin: 0;
        width: 19%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -11%;
        margin-right: 5.3%;
        text-align: center;
        
    
    }
    .fraseCliente4 {
        margin: 0;
        width: 19%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -11%;
        margin-right: 5%;
        text-align: center;
        
    
    }
    .fraseCliente5 {
        margin: 0;
        width: 20%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -11%;
        margin-right: 4.5%;
        text-align: center;
        
    
    }
    .fraseCliente6 {
        margin: 0;
        width: 19%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -10.4%;
        margin-right: 4.6%;
        text-align: center;
        
    
    }
    .fraseCliente7 {
        margin: 0;
        width: 20%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size:1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -10.8%;
        margin-right: 4.3%;
        text-align: center;
        
    
    }
    .fraseCliente8 {
        margin: 0;
        width: 22%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size:1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -12.8%;
        margin-right: 3.5%;
        text-align: center;
        
    
    }
    .frase-contato {
        margin-top: 720px;
    }
    #contatos {
        width: 100%;
        height: 690px;
    }
}
     @media (max-width:410px){
        .hamburger {
            display: block;
            margin: 0;
            width: 30px;
            height: 30px;
            margin-left:56%;
    
        }
        #home {
            width: 100%;
            height: 1000px;
    
            background: #262523;
        }
    
       
    
        .frase {
            display: flex;
            width: 350px;
            flex-direction: column;
            
            color: #F6F6F6;
            font-family: 'Noto Sans', sans-serif;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            font-size: 1.5rem;
            z-index: 99;
            margin-top: 630px;
            margin-left: 6vw;
            margin-right: 4vw;
            
    
        }
        .frase2 {
            display: flex;
            width: 85%;
            flex-direction: column;
            color: #D7D7D7;
            font-family: 'Noto Sans', sans-serif;
            font-size: 1.3rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            left: 25px;
            margin-top: -55px;
    
        }
        #funciona {
            width: 100%;
            height: 1670px;
        }
        .titulo {
            font-size: 1.6rem;
            font-family: 'Montserrat', sans-serif;
            font-weight: 600;
            display: flex;
            width: 400px;
            margin: 0;
            padding: 0;
            margin-top: 35px;
            margin-left: 32px;
        }
    
        .legenda {
            display: flex;
            width: 340px;
    
            color: #000;
            font-size: 1.2rem;
            font-family: 'Noto Sans', sans-serif;
            margin: 0;
            padding: 0;
            margin-top: 8px;
            margin-left: 32px;
    
    
    
        }
        .mobile-passo1 {
            display: flex;
            margin-top: 0px;
            color: #979595;
            font-family: 'Noto Sans', sans-serif;
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-top: 24px;
            margin-left: 32px;
        }
        .numero-mobile{
            color: #979595;
            font-family: 'Noto Sans', sans-serif;
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-left: 3px;
        }
    
        .mobile-legenda1-passo1 {
            display: flex;
            width: 300px;
            color: #514C4C;
            font-family: 'Noto Sans', sans-serif;
            font-size: 1.2rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-top: 16px;
            margin-left: 32px;
        }
    
        .mobile-linha-horizontal1 {
            display: flex;
            background: #C4BABA;
            width: 300px;
            height: 1px;
            margin-top: 16px;
            margin-left: 31px;
        }
    
    
        .mobile-passo2 {
            display: flex;
            color: #979595;
            font-family: 'Noto Sans', sans-serif;
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-top: 24px;
            margin-left: 32px;
        }
    
        .mobile-legenda-passo2 {
            display: flex;
            margin-top: 16px;
            margin-left: 32px;
            width: 300px;
            color: #514C4C;
            font-family: 'Noto Sans', sans-serif;
            font-size: 1.2rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    
        .mobile-linha-horizontal2 {
            display: flex;
            background: #C4BABA;
            width: 300px;
            height: 1px;
            margin-top: 16px;
            margin-left: 31px;
        }
    
    
        .mobile-passo3 {
            display: flex;
            margin-top: 16px;
            margin-left: 32px;
            color: #979595;
            font-family: 'Noto Sans', sans-serif;
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    
        .legenda-mobile-passo3 {
            display: flex;
            margin-top: 16px;
            margin-left: 32px;
            width: 300px;
            color: #514C4C;
            font-family: 'Noto Sans', sans-serif;
            font-size: 1.2rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    
        .mobile-linha-horizontal3 {
            display: flex;
            margin-top: 10px;
            margin-left: 31px;
            width: 300px;
            height: 1px;
            background: #C4BABA;
        }
        
        .melhor {
            margin: 0;
            padding: 0;
            margin-top: -330px;
            margin-left: 26px;
            color: #F6F6F6;
            font-family: 'Montserrat', sans-serif;
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
    
        .frase-melhor {
            margin: 0;
            padding: 0;
            margin-top: 20px;
            margin-left: 23px;
            width: 330px;
            display: flex;
            color: #D7D7D7;
            font-family: 'Noto Sans', sans-serif;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    
    
        .quantidade {
            margin: 0;
            padding: 0;
            margin-top: 6vh;
            margin-left: 24px;
            color: #000;
            font-family: 'Noto Sans', sans-serif;
            font-size: 1.4rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
    
        .frase-quantidade {
            margin: 0;
            padding: 0;
            margin-top: 8px;
            margin-left: 23px;
            color: #000;
            font-family: 'Noto Sans', sans-serif;
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    
        .quantidade2 {
            margin: 0;
            padding: 0;
            margin-left: 250px;
            margin-top: 30px;
            color: #000;
            font-family: 'Noto Sans', sans-serif;
            font-size: 1.4rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
    
        .frase-quantidade2 {
            margin: 0;
            padding: 0;
            margin-left: 255px;
            margin-top: 8px;
            color: #000;
            font-family: 'Noto Sans', sans-serif;
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-bottom: 66px;
        }
    
        .slide-track img {
            width: 357px;
            height: 234px;
    
            margin-right: 8px;
        }
    
        .frase3 {
        
            margin-top: 48px;
           
            color: #000;
            font-family: 'Noto Sans', sans-serif;
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    
    
        .imagemCliente {
            width: 100%;
            height: 350px;
            flex-shrink: 0;
            margin-top: 350px;
         margin-left: -1619px
    
        }
        .imagemCliente2 {
            width: 100%;
            height: 350px;
            flex-shrink: 0;
            margin-top: 350px;
            margin-left: -1342px;
    
        }
        .imagemCliente3 {
            width: 100%;
            height: 350px;
            flex-shrink: 0;
            margin-top: 350px;
            margin-left: -1487px;
    
        }
        .imagemCliente4 {
            width: 100%;
            height: 350px;
            flex-shrink: 0;
            margin-top: 350px;
            margin-left: -1384px;
    
        }
        .imagemCliente5 {
            width: 100%;
            height: 350px;
            flex-shrink: 0;
            margin-top: 350px;
            margin-left: -1307px;
    
        }
        .imagemCliente6 {
            width: 100%;
            height: 350px;
            flex-shrink: 0;
            margin-top: 350px;
            margin-left: -1414px;
    
        }
        .imagemCliente7 {
            width: 100%;
            height: 350px;
            flex-shrink: 0;
            margin-top: 350px;
            margin-left: -1512px;
    
        }
        .imagemCliente8 {
            width: 100%;
            height: 350px;
            flex-shrink: 0;
            margin-top: 350px;
            margin-left: -1282px;
    
        }
    
        .aspas {
            width: 30px;
            height: 25px;
            fill: #000;
            opacity: 0.5396153330802917;
            margin: 0;
          margin-left: 11.5%;
      
            margin-top: -215px;
        }
        .aspas2 {
            width: 30px;
            height: 25px;
            fill: #000;
            opacity: 0.5396153330802917;
            margin: 0;
          margin-left: 13.8%;
      
            margin-top: -210px;
        }
        .aspas3 {
            width: 30px;
            height: 25px;
            fill: #000;
            opacity: 0.5396153330802917;
            margin: 0;
          margin-left: 12.8%;
      
            margin-top: -210px;
        }
        .aspas4 {
            width: 30px;
            height: 25px;
            fill: #000;
            opacity: 0.5396153330802917;
            margin: 0;
          margin-left: 13.6%;
      
            margin-top: -210px;
        }
        .aspas5 {
            width: 30px;
            height: 25px;
            fill: #000;
            opacity: 0.5396153330802917;
            margin: 0;
          margin-left: 14.5%;
      
            margin-top: -210px;
        }
        .aspas6 {
            width: 30px;
            height: 25px;
            fill: #000;
            opacity: 0.5396153330802917;
            margin: 0;
          margin-left: 13%;
      
            margin-top: -210px;
        }
        .aspas7 {
            width: 30px;
            height: 25px;
            fill: #000;
            opacity: 0.5396153330802917;
            margin: 0;
          margin-left: 12%;
      
            margin-top: -210px;
        }
        .aspas8 {
            width: 30px;
            height: 25px;
            fill: #000;
            opacity: 0.5396153330802917;
            margin: 0;
          margin-left: 14.8%;
      
            margin-top: -210px;
        }
    
        .fraseCliente {
         
            width:17%;
           display: flex;
         
            color: #514C4C;
            font-family: 'Noto Sans', sans-serif;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-top: -180px;
            margin-left: -9.8%;
            margin-right: 6.5%;
           text-align: center;
          
            
        
        }
        .fraseCliente2 {
            margin: 0;
            width: 19%;
            display: flex;
            color: #514C4C;
            font-family: 'Noto Sans', sans-serif;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-top: -170px;
            margin-left: -11%;
            margin-right: 4.7%;
           text-align: center;
            
        
        }
        .fraseCliente3 {
            margin: 0;
            width: 19%;
            display: flex;
            color: #514C4C;
            font-family: 'Noto Sans', sans-serif;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-top: -170px;
            margin-left: -11%;
            margin-right: 5.3%;
            text-align: center;
            
        
        }
        .fraseCliente4 {
            margin: 0;
            width: 19%;
            display: flex;
            color: #514C4C;
            font-family: 'Noto Sans', sans-serif;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-top: -170px;
            margin-left: -11%;
            margin-right: 5%;
            text-align: center;
            
        
        }
        .fraseCliente5 {
            margin: 0;
            width: 20%;
            display: flex;
            color: #514C4C;
            font-family: 'Noto Sans', sans-serif;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-top: -170px;
            margin-left: -11%;
            margin-right: 4.5%;
            text-align: center;
            
        
        }
        .fraseCliente6 {
            margin: 0;
            width: 19%;
            display: flex;
            color: #514C4C;
            font-family: 'Noto Sans', sans-serif;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-top: -170px;
            margin-left: -10.4%;
            margin-right: 4.6%;
            text-align: center;
            
        
        }
        .fraseCliente7 {
            margin: 0;
            width: 20%;
            display: flex;
            color: #514C4C;
            font-family: 'Noto Sans', sans-serif;
            font-size:1rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-top: -170px;
            margin-left: -10.8%;
            margin-right: 4.3%;
            text-align: center;
            
        
        }
        .fraseCliente8 {
            margin: 0;
            width: 22%;
            display: flex;
            color: #514C4C;
            font-family: 'Noto Sans', sans-serif;
            font-size:1rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-top: -170px;
            margin-left: -12.8%;
            margin-right: 3.5%;
            text-align: center;
            
        
        }
    
        .cliente {
            color: #000;
            font-family: 'Noto Sans', sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            display: flex;
            margin: 0;
            margin-top: -40px;
            margin-left: -16%;
            width: 300px;
    
        }
    
    
    
    
    
    
    
        .butao {
            border: 1px solid black;
            transition: border-color 0.5s, background-color 0.5s, color 0.5s;
            width: 100%;
            justify-content: center;
            padding: 15px 28px;
            flex-shrink: 0;
            margin-left:0%;
            margin-top: -60px;
            margin-right:0;
            background-color: #000;
            gap: 10px;
            display: flex;
            cursor: pointer
        }
        
        
        .enviar {
            text-align: center;
            text-decoration: none;
            color: #ffff;
            list-style: none;
            font-family: 'Noto Sans', sans-serif;
            font-size:0.7rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            list-style: none;
        }
    
        .frase-contato {
           
           
    
            width: 80%;
            text-align: center;
           margin-left: 8.5%;
           margin-right:30%;
          
            font-size: 26px;
           
           margin-top: 680px;
     
        }
    
        .frase-contato2 {
           
            margin-top: 8px;
          
            width: 80%;
            color: #000;
          margin-left: 8.8%;
            font-family: 'Noto Sans', sans-serif;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
        #contatos {
            width: 100%;
            height: 690px;
        }
        #projeto{
            width: 100%;
            height: 1000px;
        }
        footer {
            width: 100%;
            height: 296px;
            flex-shrink: 0;
        }
    
        .footer-flex {
            margin: 0;
    
        }
    
        .icon-footer {
          
            width: 36px;
            height: 36px;
          
            margin-top: 24.5px;
        }
    
        .frase-lado-icon {
            margin: 0;
            color: #F6F6F6;
            font-family: 'Yeseva One', cursive;
            font-size: 2rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-top: 24.5px;
            
        }
    
        .funcao {
            margin: 0;
            color: #FFF;
            font-family: 'Noto Sans', sans-serif;
            font-size: 22px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          
            margin-top: 18px;
        }
    
        .endereco {
            margin: 0;
            color: #FFF;
            font-family: 'Noto Sans', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
           
            margin-top: 16px;
        }
    
        .email-contato {
            margin: 0;
            color: #FFF;
            font-family: 'Noto Sans', sans-serif;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            
            margin-top: 16px;
        }
    
        .telefone {
            margin: 0;
            color: #FFF;
            font-family: 'Noto Sans', sans-serif;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          
            margin-top: 16px;
        }
    
        .insta {
            margin: 0;
            width: 35px;
            height: 35px;
            margin-top: 16px;
        
        }
    
        .icon-whatsapp {
            margin: 0;
            width: 35px;
            height: 35px;
            margin-top: 16px;
            margin-left: 20px;
        }
    
        .linkedin {
            margin: 0;
            width: 35px;
            height: 35px;
            margin-top: 16px;
            margin-left: 20px;
        }
     }
     @media (max-width:378px){
        .como-funciona a:hover::after {
            margin-left: 0px;
    
    
            width: 67%;
            transition: 0.2s;
        }
    
        .item1 a:hover::after {
            margin-left: 0px;
    
    
            width: 26%;
        }
    
        .item2 a:hover::after {
            margin-left: 0px;
    
    
            width: 35%;
        }
    
        .item3 a:hover::after {
            margin-left: 0px;
    
    
            width: 35%;
        }
        .hamburger {
            display: block;
            margin: 0;
            width: 30px;
            height: 30px;
            margin-left:54%;
    
        }
        #home {
            width: 100%;
            height: 1000px;
    
            background: #262523;
        }
    
       
    
        .frase {
            display: flex;
            width: 350px;
            flex-direction: column;
            
            color: #F6F6F6;
            font-family: 'Noto Sans', sans-serif;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            font-size: 1.5rem;
            z-index: 99;
            margin-top: 630px;
            margin-left: 6vw;
            margin-right: 4vw;
            
    
        }
        .frase2 {
            display: flex;
            width: 85%;
            flex-direction: column;
            color: #D7D7D7;
            font-family: 'Noto Sans', sans-serif;
            font-size: 1.3rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            left: 25px;
            margin-top: -55px;
    
        }
        #funciona {
            width: 100%;
            height: 1670px;
        }
        .titulo {
            font-size: 1.6rem;
            font-family: 'Montserrat', sans-serif;
            font-weight: 600;
            display: flex;
            width: 400px;
            margin: 0;
            padding: 0;
            margin-top: 60px;
            margin-left: 25px;
        }
    
        .legenda {
            display: flex;
            width: 340px;
    
            color: #000;
            font-size: 1.2rem;
            font-family: 'Noto Sans', sans-serif;
            margin: 0;
            padding: 0;
            margin-top: 8px;
            margin-left: 25px;
    
    
    
        }
        .mobile-passo1 {
            display: flex;
            margin-top: 0px;
            color: #979595;
            font-family: 'Noto Sans', sans-serif;
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-top: 24px;
            margin-left: 32px;
        }
        .numero-mobile{
            color: #979595;
            font-family: 'Noto Sans', sans-serif;
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-left: 3px;
        }
    
        .mobile-legenda1-passo1 {
            display: flex;
            width: 300px;
            color: #514C4C;
            font-family: 'Noto Sans', sans-serif;
            font-size: 1.2rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-top: 16px;
            margin-left: 32px;
        }
    
        .mobile-linha-horizontal1 {
            display: flex;
            background: #C4BABA;
            width: 300px;
            height: 1px;
            margin-top: 16px;
            margin-left: 31px;
        }
    
    
        .mobile-passo2 {
            display: flex;
            color: #979595;
            font-family: 'Noto Sans', sans-serif;
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-top: 24px;
            margin-left: 32px;
        }
    
        .mobile-legenda-passo2 {
            display: flex;
            margin-top: 16px;
            margin-left: 32px;
            width: 300px;
            color: #514C4C;
            font-family: 'Noto Sans', sans-serif;
            font-size: 1.2rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    
        .mobile-linha-horizontal2 {
            display: flex;
            background: #C4BABA;
            width: 300px;
            height: 1px;
            margin-top: 16px;
            margin-left: 31px;
        }
    
    
        .mobile-passo3 {
            display: flex;
            margin-top: 16px;
            margin-left: 32px;
            color: #979595;
            font-family: 'Noto Sans', sans-serif;
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    
        .legenda-mobile-passo3 {
            display: flex;
            margin-top: 16px;
            margin-left: 32px;
            width: 300px;
            color: #514C4C;
            font-family: 'Noto Sans', sans-serif;
            font-size: 1.2rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    
        .mobile-linha-horizontal3 {
            display: flex;
            margin-top: 10px;
            margin-left: 31px;
            width: 300px;
            height: 1px;
            background: #C4BABA;
        }
        
        .melhor {
            margin: 0;
            padding: 0;
            margin-top: -310px;
            margin-left: 26px;
            color: #F6F6F6;
            font-family: 'Montserrat', sans-serif;
            font-size: 1.3rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
    
        .frase-melhor {
            margin: 0;
            padding: 0;
            margin-top: 20px;
            margin-left: 23px;
            width: 330px;
            display: flex;
            color: #D7D7D7;
            font-family: 'Noto Sans', sans-serif;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    
    
        .quantidade {
            margin: 0;
            padding: 0;
            margin-top: 6vh;
            margin-left: 24px;
            color: #000;
            font-family: 'Noto Sans', sans-serif;
            font-size: 1.4rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
    
        .frase-quantidade {
            margin: 0;
            padding: 0;
            margin-top: 8px;
            margin-left: 23px;
            color: #000;
            font-family: 'Noto Sans', sans-serif;
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    
        .quantidade2 {
            margin: 0;
            padding: 0;
            margin-left: 250px;
            margin-top: 30px;
            color: #000;
            font-family: 'Noto Sans', sans-serif;
            font-size: 1.4rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
    
        .frase-quantidade2 {
            margin: 0;
            padding: 0;
            margin-left: 255px;
            margin-top: 8px;
            color: #000;
            font-family: 'Noto Sans', sans-serif;
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-bottom: 66px;
        }
    
        .slide-track img {
            width: 355px;
            height: 234px;
    
            margin-right: 8px;
        }
    
        .frase3 {
        
            margin-top: 48px;
           
            color: #000;
            font-family: 'Noto Sans', sans-serif;
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    
    
        .imagemCliente {
            width: 100%;
            height: 350px;
            flex-shrink: 0;
            margin-top: 350px;
         margin-left: -1619px
    
        }
        .imagemCliente2 {
            width: 100%;
            height: 350px;
            flex-shrink: 0;
            margin-top: 350px;
            margin-left: -1342px;
    
        }
        .imagemCliente3 {
            width: 100%;
            height: 350px;
            flex-shrink: 0;
            margin-top: 350px;
            margin-left: -1487px;
    
        }
        .imagemCliente4 {
            width: 100%;
            height: 350px;
            flex-shrink: 0;
            margin-top: 350px;
            margin-left: -1384px;
    
        }
        .imagemCliente5 {
            width: 100%;
            height: 350px;
            flex-shrink: 0;
            margin-top: 350px;
            margin-left: -1307px;
    
        }
        .imagemCliente6 {
            width: 100%;
            height: 350px;
            flex-shrink: 0;
            margin-top: 350px;
            margin-left: -1414px;
    
        }
        .imagemCliente7 {
            width: 100%;
            height: 350px;
            flex-shrink: 0;
            margin-top: 350px;
            margin-left: -1512px;
    
        }
        .imagemCliente8 {
            width: 100%;
            height: 350px;
            flex-shrink: 0;
            margin-top: 350px;
            margin-left: -1282px;
    
        }
    
        .aspas {
            width: 30px;
            height: 25px;
            fill: #000;
            opacity: 0.5396153330802917;
            margin: 0;
          margin-left: 11%;
      
            margin-top: -215px;
        }
        .aspas2 {
            width: 30px;
            height: 25px;
            fill: #000;
            opacity: 0.5396153330802917;
            margin: 0;
          margin-left: 13.4%;
      
            margin-top: -210px;
        }
        .aspas3 {
            width: 30px;
            height: 25px;
            fill: #000;
            opacity: 0.5396153330802917;
            margin: 0;
          margin-left: 12.2%;
      
            margin-top: -210px;
        }
        .aspas4 {
            width: 30px;
            height: 25px;
            fill: #000;
            opacity: 0.5396153330802917;
            margin: 0;
          margin-left: 12.8%;
      
            margin-top: -210px;
        }
        .aspas5 {
            width: 30px;
            height: 25px;
            fill: #000;
            opacity: 0.5396153330802917;
            margin: 0;
          margin-left: 13.5%;
      
            margin-top: -210px;
        }
        .aspas6 {
            width: 30px;
            height: 25px;
            fill: #000;
            opacity: 0.5396153330802917;
            margin: 0;
          margin-left: 12.5%;
      
            margin-top: -210px;
        }
        .aspas7 {
            width: 30px;
            height: 25px;
            fill: #000;
            opacity: 0.5396153330802917;
            margin: 0;
          margin-left: 11.2%;
      
            margin-top: -210px;
        }
        .aspas8 {
            width: 30px;
            height: 25px;
            fill: #000;
            opacity: 0.5396153330802917;
            margin: 0;
          margin-left: 14%;
      
            margin-top: -210px;
        }
    
        .fraseCliente {
         
            width:17%;
           display: flex;
         
            color: #514C4C;
            font-family: 'Noto Sans', sans-serif;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-top: -180px;
            margin-left: -9.8%;
            margin-right: 6.5%;
           text-align: center;
          
            
        
        }
        .fraseCliente2 {
            margin: 0;
            width: 19%;
            display: flex;
            color: #514C4C;
            font-family: 'Noto Sans', sans-serif;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-top: -170px;
            margin-left: -11%;
            margin-right: 4.8%;
           text-align: center;
            
        
        }
        .fraseCliente3 {
            margin: 0;
            width: 19%;
            display: flex;
            color: #514C4C;
            font-family: 'Noto Sans', sans-serif;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-top: -170px;
            margin-left: -11%;
            margin-right: 5.3%;
            text-align: center;
            
        
        }
        .fraseCliente4 {
            margin: 0;
            width: 19%;
            display: flex;
            color: #514C4C;
            font-family: 'Noto Sans', sans-serif;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-top: -170px;
            margin-left: -11%;
            margin-right: 5.2%;
            text-align: center;
            
        
        }
        .fraseCliente5 {
            margin: 0;
            width: 20%;
            display: flex;
            color: #514C4C;
            font-family: 'Noto Sans', sans-serif;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-top: -170px;
            margin-left: -11%;
            margin-right: 4.5%;
            text-align: center;
            
        
        }
        .fraseCliente6 {
            margin: 0;
            width: 19%;
            display: flex;
            color: #514C4C;
            font-family: 'Noto Sans', sans-serif;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-top: -170px;
            margin-left: -10.4%;
            margin-right: 4.6%;
            text-align: center;
            
        
        }
        .fraseCliente7 {
            margin: 0;
            width: 20%;
            display: flex;
            color: #514C4C;
            font-family: 'Noto Sans', sans-serif;
            font-size:1rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-top: -170px;
            margin-left: -10.8%;
            margin-right: 4.3%;
            text-align: center;
            
        
        }
        .fraseCliente8 {
            margin: 0;
            width: 22%;
            display: flex;
            color: #514C4C;
            font-family: 'Noto Sans', sans-serif;
            font-size:1rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-top: -170px;
            margin-left: -12.8%;
            margin-right: 3.5%;
            text-align: center;
            
        
        }
    
        .cliente {
            color: #000;
            font-family: 'Noto Sans', sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            display: flex;
            margin: 0;
            margin-top: -40px;
            margin-left: -16%;
            width: 300px;
    
        }
    
    
    
    
    
    
    
        .butao {
            border: 1px solid black;
            transition: border-color 0.5s, background-color 0.5s, color 0.5s;
            width: 100%;
            justify-content: center;
            padding: 15px 28px;
            flex-shrink: 0;
            margin-left:0%;
            margin-top: -60px;
            margin-right:0;
            background-color: #000;
            gap: 10px;
            display: flex;
            cursor: pointer
        }
        
        
        .enviar {
            text-align: center;
            text-decoration: none;
            color: #ffff;
            list-style: none;
            font-family: 'Noto Sans', sans-serif;
            font-size:0.6rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            list-style: none;
        }
    
        .frase-contato {
           
           
    
            width: 80%;
            text-align: center;
           margin-left: 8.5%;
           margin-right:30%;
          
            font-size: 26px;
           margin-top: 660px;
           
     
        }
    
        .frase-contato2 {
           
            margin-top: 8px;
          
            width: 80%;
            color: #000;
          margin-left: 8.8%;
            font-family: 'Noto Sans', sans-serif;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
        #contatos {
            width: 100%;
            height: 690px;
        }
        #projeto{
            width: 100%;
            height: 1000px;
        }
        footer {
            width: 100%;
            height: 296px;
            flex-shrink: 0;
        }
    
        .footer-flex {
            margin: 0;
    
        }
    
        .icon-footer {
          
            width: 36px;
            height: 36px;
          
            margin-top: 24.5px;
        }
    
        .frase-lado-icon {
            margin: 0;
            color: #F6F6F6;
            font-family: 'Yeseva One', cursive;
            font-size: 2rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-top: 24.5px;
            
        }
    
        .funcao {
            margin: 0;
            color: #FFF;
            font-family: 'Noto Sans', sans-serif;
            font-size: 22px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          
            margin-top: 18px;
        }
    
        .endereco {
            margin: 0;
            color: #FFF;
            font-family: 'Noto Sans', sans-serif;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
           
            margin-top: 16px;
        }
    
        .email-contato {
            margin: 0;
            color: #FFF;
            font-family: 'Noto Sans', sans-serif;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            
            margin-top: 16px;
        }
    
        .telefone {
            margin: 0;
            color: #FFF;
            font-family: 'Noto Sans', sans-serif;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          
            margin-top: 16px;
        }
    
        .insta {
            margin: 0;
            width: 35px;
            height: 35px;
            margin-top: 16px;
        
        }
    
        .icon-whatsapp {
            margin: 0;
            width: 35px;
            height: 35px;
            margin-top: 16px;
            margin-left: 20px;
        }
    
        .linkedin {
            margin: 0;
            width: 35px;
            height: 35px;
            margin-top: 16px;
            margin-left: 20px;
        }
     }
@media (max-width:360px){
    .como-funciona a:hover::after {
        margin-left: 0px;


        width: 71%;
        transition: 0.2s;
    }

    .item1 a:hover::after {
        margin-left: 0px;


        width: 28%;
    }

    .item2 a:hover::after {
        margin-left: 0px;


        width: 37%;
    }

    .item3 a:hover::after {
        margin-left: 0px;


        width: 37%;
    }
     .aspas {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 10.5%;
  
        margin-top: -215px;
    }
    .aspas2 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 13%;
  
        margin-top: -210px;
    }
    .aspas3 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 11.7%;
  
        margin-top: -210px;
    }
    .aspas4 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 12.5%;
  
        margin-top: -210px;
    }
    .aspas5 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 13.2%;
  
        margin-top: -210px;
    }
    .aspas6 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 11.8%;
  
        margin-top: -210px;
    }
    .aspas7 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 10.9%;
  
        margin-top: -210px;
    }
    .aspas8 {
        width: 30px;
        height: 25px;
        fill: #000;
        opacity: 0.5396153330802917;
        margin: 0;
      margin-left: 13.7%;
  
        margin-top: -210px;
    }

    .fraseCliente {
     
        width:17%;
       display: flex;
     
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -180px;
        margin-left: -9.8%;
        margin-right: 6.5%;
       text-align: center;
      
        
    
    }
    .fraseCliente2 {
        margin: 0;
        width: 19%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -11%;
        margin-right: 4.8%;
       text-align: center;
        
    
    }
    .fraseCliente3 {
        margin: 0;
        width: 19%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -11%;
        margin-right: 5.3%;
        text-align: center;
        
    
    }
    .fraseCliente4 {
        margin: 0;
        width: 19%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -11%;
        margin-right: 5.2%;
        text-align: center;
        
    
    }
    .fraseCliente5 {
        margin: 0;
        width: 20%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -11%;
        margin-right: 4.5%;
        text-align: center;
        
    
    }
    .fraseCliente6 {
        margin: 0;
        width: 19%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -10.4%;
        margin-right: 4.6%;
        text-align: center;
        
    
    }
    .fraseCliente7 {
        margin: 0;
        width: 20%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size:1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -10.8%;
        margin-right: 4.3%;
        text-align: center;
        
    
    }
    .fraseCliente8 {
        margin: 0;
        width: 22%;
        display: flex;
        color: #514C4C;
        font-family: 'Noto Sans', sans-serif;
        font-size:1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -170px;
        margin-left: -12.8%;
        margin-right: 3.5%;
        text-align: center;
        
    
    }
    .frase-contato {
        margin-top: 630px;
    }
    #contatos {
        width: 100%;
        height: 690px;
    }
}